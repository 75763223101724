import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EAgency } from '../../constants/';
import { EOrderFilter } from '../../../models/consts';
import { IRole } from '../../types/common';

interface ICustomerOrdersProps {
  params: IOrderParams;
  key: string;
  filter: string;
  role: IRole;
}
interface IOrderParams {
  isAssignWorker?: boolean;
  limit: number;
  sortStartDate?: string;
  offset: number;
  statuses?: string[];
}

const getCustomerOrders = createAsyncThunk<any[], ICustomerOrdersProps>(
  EAgency.customerOrders,
  async ({ params, filter }) => {
    if (filter === EOrderFilter.PAST_ORDERS) {
      params['statuses'] = ['completed', 'terminated', 'terminated_during_trial'];
    } else if (filter === EOrderFilter.UNREALIZED_ORDERS) {
      params['statuses'] = ['expired', 'canceled'];
    } else if (filter === EOrderFilter.UNASSIGNED || filter === EOrderFilter.ASSIGNED) {
      params['statuses'] = [
        'created',
        'waiting_for_payment',
        'ready_to_start',
        'active',
        'on_hold',
        'pending_confirmation',
      ];
      params['isAssignWorker'] = filter === EOrderFilter.ASSIGNED;
    } else if (filter === EOrderFilter.CURRENT) {
      params['statuses'] = [
        'created',
        'waiting_for_payment',
        'ready_to_start',
        'active',
        'on_hold',
        'pending_confirmation',
      ];
    }
    const { data } = await instance.get(`agency/customers`, { params });

    return data;
  },
);

export default getCustomerOrders;
