import { SENIORITY_LEVELS } from 'models/consts';

export const getLevelByLevelId = (levelId: number) => {
  for (const level in SENIORITY_LEVELS) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (SENIORITY_LEVELS[level].id === levelId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return SENIORITY_LEVELS[level];
    }
  }
  return undefined;
};

export const getTypeByTypeId = (typeId: string) => {
  switch (typeId) {
    case 'a':
      return 'Agency Specialists Only';
    case 'n':
      return 'Independent Specialists Only';
    default:
      return '';
  }
};
