import React, { FunctionComponent } from 'react';
import { ISvgProps } from '../../models/inner-models';

export const AssigmentTurnedInIcon: FunctionComponent<ISvgProps> = ({
  className,
  height = 42,
  width = 156,
}) => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_11535_21016"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="66"
        height="66"
      >
        <rect width="66" height="66" fill="#FFCA28" />
      </mask>
      <g mask="url(#mask0_11535_21016)">
        <path
          d="M29.0812 38.1562L22 31.075C21.6333 30.8 21.2552 30.651 20.8656 30.6281C20.476 30.6052 20.1208 30.7542 19.8 31.075C19.4333 31.3958 19.2615 31.7625 19.2844 32.175C19.3073 32.5875 19.4792 32.9542 19.8 33.275L27.6375 41.3187C28.0042 41.6854 28.4625 41.8688 29.0125 41.8688C29.5625 41.8688 30.0437 41.6854 30.4562 41.3187L46.2 25.575C46.475 25.2542 46.624 24.876 46.6469 24.4406C46.6698 24.0052 46.5208 23.6271 46.2 23.3063C45.8792 22.9854 45.501 22.825 45.0656 22.825C44.6302 22.825 44.2521 22.9854 43.9313 23.3063L29.0812 38.1562ZM13.6125 53.2812H52.3875C52.6167 53.2812 52.8229 53.1896 53.0062 53.0062C53.1896 52.8229 53.2812 52.6167 53.2812 52.3875V13.6125C53.2812 13.3833 53.1896 13.1771 53.0062 12.9937C52.8229 12.8104 52.6167 12.7188 52.3875 12.7188H13.6125C13.3833 12.7188 13.1771 12.8104 12.9937 12.9937C12.8104 13.1771 12.7188 13.3833 12.7188 13.6125V52.3875C12.7188 52.6167 12.8104 52.8229 12.9937 53.0062C13.1771 53.1896 13.3833 53.2812 13.6125 53.2812ZM13.6125 56.375C12.5125 56.375 11.5729 55.9854 10.7937 55.2063C10.0146 54.4271 9.625 53.4875 9.625 52.3875V13.6125C9.625 12.5125 10.0146 11.5729 10.7937 10.7937C11.5729 10.0146 12.5125 9.625 13.6125 9.625H27.7062C27.6146 8.15833 28.0844 6.875 29.1156 5.775C30.1469 4.675 31.4417 4.125 33 4.125C34.5583 4.125 35.8646 4.675 36.9188 5.775C37.9729 6.875 38.4312 8.15833 38.2938 9.625H52.3875C53.4875 9.625 54.4271 10.0146 55.2063 10.7937C55.9854 11.5729 56.375 12.5125 56.375 13.6125V52.3875C56.375 53.4875 55.9854 54.4271 55.2063 55.2063C54.4271 55.9854 53.4875 56.375 52.3875 56.375H13.6125ZM33 11.6187C33.5958 11.6187 34.1344 11.3896 34.6156 10.9312C35.0969 10.4729 35.3375 9.92292 35.3375 9.28125C35.3375 8.63958 35.0969 8.08958 34.6156 7.63125C34.1344 7.17292 33.5958 6.94375 33 6.94375C32.3583 6.94375 31.8198 7.17292 31.3844 7.63125C30.949 8.08958 30.7313 8.63958 30.7313 9.28125C30.7313 9.92292 30.949 10.4729 31.3844 10.9312C31.8198 11.3896 32.3583 11.6187 33 11.6187Z"
          fill="#FFCA28"
        />
      </g>
    </svg>
  );
};
