import React from 'react';

import sortBy from 'lodash/sortBy';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { IConsultantExpertises } from 'store/types/expertise';
import { DirectorySearchResult } from 'store/types/directorySearch';

import useExpertiseModulesInfo from 'hooks/useExpertiseModulesInfo';

import SeniorityLevel from 'components/SeniorityLevel';
import HighlightedView from 'components/DirectorySearch/HighlightedView';

interface IConsultantModulesSettings {
  value?: IConsultantExpertises | null;
  consultant?: DirectorySearchResult;
}

const CellSticky = styled(TableCell)({
  position: 'sticky',
  left: 0,
  background: 'white',
  zIndex: 1,
});

const ModulesSettings: React.FunctionComponent<IConsultantModulesSettings> = ({
  value,
  consultant,
}) => {
  const { works, modules } = useExpertiseModulesInfo(value?.modulesWorks);
  const sortedWorks = sortBy(works, 'id');

  return (
    <TableContainer sx={{ mb: 4 }}>
      <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
        <TableHead>
          <TableRow>
            <CellSticky padding="normal" sx={{ borderBottom: 'none', width: 240 }}>
              <Typography variant="subtitle1">Proficiency</Typography>
            </CellSticky>
            {sortedWorks?.map(work => (
              <TableCell
                key={work.id}
                align="center"
                padding="normal"
                sx={{ borderBottom: 'none' }}
              >
                <Typography variant="body1">
                  <b>{work.name}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {modules?.map(module => {
            const selectedModules = value?.modulesWorks?.filter(i => i.moduleId === module.id);

            return (
              <TableRow key={module.id}>
                <CellSticky padding="normal" sx={{ borderBottom: 'none' }}>
                  <Typography variant="body1">
                    {consultant && consultant?.highlights?.includes('expertises:productName') ? (
                      <HighlightedView text={module.name || ''} />
                    ) : (
                      module.name
                    )}
                  </Typography>
                </CellSticky>
                {sortedWorks?.map(work => {
                  const selectedWork = selectedModules?.find(i => i.workId === work.id);
                  return (
                    <TableCell
                      key={work.id}
                      align="center"
                      padding="normal"
                      sx={{
                        backgroundColor: '#F5F5F5',
                        borderBottom: 'none',
                        padding: '0 10px',
                      }}
                    >
                      <SeniorityLevel
                        moduleId={module.id}
                        workId={work.id}
                        value={selectedWork ? selectedWork.level : undefined}
                        disabled
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModulesSettings;
