import React, { FC } from 'react';

import Chip from '@mui/material/Chip';

import { ITagItem } from 'store/reducers/tags';
import { DirectorySearchResult } from 'store/types/directorySearch';

import { sortingBy } from 'utils/transformers';
import HighlightedView from 'components/DirectorySearch/HighlightedView';

interface IAdditionalSkills {
  tags: ITagItem[];
  consultant?: DirectorySearchResult;
}
const AdditionalSkills: FC<IAdditionalSkills> = ({ consultant, tags }) => (
  <>
    {!!tags?.length &&
      sortingBy(tags, 'UserTags.showIndex').map((item: any) => (
        <Chip
          sx={{ mr: 1.5, mb: 2 }}
          key={item.id}
          label={
            consultant?.highlights?.includes('tags') ? (
              <HighlightedView text={item.name} />
            ) : (
              item.name
            )
          }
          variant="outlined"
          size="small"
        />
      ))}
  </>
);

export default AdditionalSkills;
