import React, { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import { useAppSelector } from 'store/hooks';
import { availabilitiesSelector } from 'store/selectors/getCommonSelector';

import { BootstrapInput } from '../../CustomFields';

const WeeklyCommitmentForm: FunctionComponent<any> = ({ setNewAvailability, setNewWeeks }) => {
  const availabilities = useAppSelector(availabilitiesSelector);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [availability, weeks] = watch(['availability', 'weeks']);

  const [open, setOpen] = useState({ type: '', isOpen: false });

  const handleInputChange = (type: string) => {
    setOpen({ type, isOpen: false });
  };

  const handleOpen = (type: string) => {
    setOpen({ type, isOpen: true });
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={5} sm={6}>
        <FormGroup>
          <InputLabel>Change weekly commitment</InputLabel>
          <Controller
            control={control}
            name="availability"
            rules={{
              validate: {
                requiredAtLeastOne: value =>
                  !!value || !!weeks || 'Required filled at least one field',
                maxLength: value => value <= 40 || 'Value cannot be more than 40',
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                open={open.type === 'availability' && open.isOpen}
                onOpen={() => handleOpen('availability')}
                onClose={() => handleInputChange('availability')}
                onChange={(_, value) => {
                  setNewAvailability(value);
                  field.onChange(value);
                }}
                onInputChange={(_, value) => {
                  field.onChange(value);
                  handleInputChange('availability');
                }}
                options={availabilities}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    onChange={(event: any) => {
                      let { value } = event.target;

                      if (value.startsWith('0') && value.length > 1) {
                        value = value.substring(1);
                      }

                      const isValidInput = /^[1-9]\d*$/;
                      if (isValidInput.test(value) || value === '') {
                        field.onChange(value);
                      }
                    }}
                    data-test="weeksNeedField"
                    error={
                      !!errors.availability && errors.availability.type !== 'requiredAtLeastOne'
                    }
                    helperText={errors.availability?.message}
                  />
                )}
              />
            )}
          />
        </FormGroup>
      </Grid>
      <Grid xs={7} sm={6}>
        <FormGroup>
          <InputLabel>Change number of weeks</InputLabel>
          <Controller
            control={control}
            name="weeks"
            rules={{
              validate: {
                requiredAtLeastOne: value =>
                  !!value || !!availability || 'Required filled at least one field',
                maxLength: value => value <= 100 || 'Value cannot be more than 100',
              },
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                open={open.type === 'weeks' && open.isOpen}
                onOpen={() => handleOpen('weeks')}
                onClose={() => handleInputChange('weeks')}
                onChange={(_, value) => {
                  setNewWeeks(value);
                  field.onChange(value);
                }}
                onInputChange={(_, value) => {
                  field.onChange(value);
                  handleInputChange('weeks');
                }}
                options={[1, 2, 3, 4, 5]}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    onChange={(event: any) => {
                      let { value } = event.target;

                      if (value.startsWith('0') && value.length > 1) {
                        value = value.substring(1);
                      }

                      const isValidInput = /^[1-9]\d*$/;
                      if (isValidInput.test(value) || value === '') {
                        field.onChange(value);
                      }
                    }}
                    data-test="weeksNeedField"
                    error={!!errors.weeks && errors.weeks.type !== 'requiredAtLeastOne'}
                    helperText={errors.weeks?.message}
                  />
                )}
              />
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default WeeklyCommitmentForm;
