import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const StyledGridWrap = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 0,
      boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.1)',
      height: 295,
      width: '100vw',
      zIndex: '-1',
    },
  }),
);

const StyledCommon = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    lineHeight: 1.2,
    wordBreak: 'break-word',
    maxWidth: '100%',
    maxHeight: {
      lg: 72,
    },
    overflow: {
      lg: 'hidden',
    },
    textOverflow: {
      lg: 'ellipsis',
    },
  }),
);

const StyledName = styled(StyledCommon)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: 22,
      sm: 26,
      lg: 30,
    },
  }),
);

const StyledShortDescriptions = styled(StyledCommon)(({ theme }) =>
  theme.unstable_sx({
    mb: 1,
  }),
);

const StyledIconBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: {
      sm: 'column',
      xl: 'row',
    },
    flexWrap: {
      xs: 'wrap',
      xl: 'nowrap',
    },
    justifyContent: {
      xs: 'end',
      sm: 'start',
    },
  }),
);

const StyledDescription = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 6,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const StyledStarChip = styled(Chip)({
  fontSize: '.875rem',
  fontWeight: 700,
  flexDirection: 'row-reverse',
  '& svg.MuiChip-icon': {
    marginRight: 8,
    marginLeft: -6,
  },
});

const Accordion = styled(MuiAccordion)({
  border: 'none',
  '&.Mui-expanded': {
    margin: 0,
  },
  '&.Mui-expanded::before': {
    opacity: 1,
  },
});

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) =>
  theme.unstable_sx({
    padding: 0,
    border: 'none',
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      display: 'block',
      my: 0,
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
      '&.Mui-expanded': {
        my: 0,
        minHeight: '48px',
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      alignSelf: {
        xs: 'start',
        lg: 'center',
      },
      mt: {
        xs: 1.5,
        lg: 0,
      },
    },
  }),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) =>
  theme.unstable_sx({
    margin: 0,
    padding: 0,
    paddingTop: 2,
  }),
);

export {
  StyledCommon,
  StyledDescription,
  StyledGridWrap,
  StyledIconBox,
  StyledName,
  StyledShortDescriptions,
  StyledStarChip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
};
