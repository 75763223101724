export enum EOrder {
  getList = 'order/getList',
  changeOrderStatus = 'order/changeOrderStatus',
  changeName = 'order/changeName',
  extendHours = 'order/extendHours',
  fetchExtendHours = 'order/fetchExtendHours',
  earlyOrderComplete = 'order/earlyComplete',
  adjustWeeklyBillings = 'order/adjustWeeklyBillings',
  submitWeeklyBillings = 'order/submitWeeklyBillings',
  getOrderByHashLink = 'order/getOrderByHashLink',
  payOrderByHashCode = 'order/payOrderByHashCode',
  checkStripeCoupons = 'order/checkStripeCoupons',

  acceptChanges = 'order/acceptChanges',
  approveOrderComplete = 'order/approveComplete',
  approveWeeklyBillings = 'order/approveWeeklyBillings',
  addBillingContact = 'order/addBillingContact',
  changeOrder = 'order/change',
  create = 'order/create',
  createFeedback = 'order/createFeedback',
  declineOrderComplete = 'order/declineComplete',
  declineWeeklyBillings = 'order/declineWeeklyBillings',
  fetchSharedLinks = 'order/fetchSharedLinks',
  fetchWeeklyBillings = 'order/fetchWeeklyBillings',
  getFeedbackByOrder = 'order/getFeedbackByOrder',
  getOrderById = 'order/getOrderById',
  getOrderHistory = 'order/getOrderHistory',
  getOrderBalance = 'order/getOrderBalance',
  getPurchaseOrderArchive = 'order/fetchPurchaseOrderArchive',
  rejectChanges = 'order/rejectChanges',
  sendDraftNotification = 'order/sendDraftNotification',
  updateOrderTimeline = 'order/updateOrderTimeline',
  getPreOrderHistory = 'order/getPreOrderHistory',

  createCuratedOrderDraft = 'order/createCuratedOrderDraft',
  updateCuratedOrderDraft = 'order/updateCuratedOrderDraft',
  fetchCuratedOrderDraft = 'order/fetchCuratedOrderDraft',
  deleteCuratedOrderDraft = 'order/deleteCuratedOrderDraft',
  curatedOrderInviteCustomer = 'order/curatedOrderInviteCustomer',
  updateCuratedOrderInviteCustomer = 'order/updateCuratedOrderInviteCustomer',
  finalizeCuratedOrder = 'order/finalizeCuratedOrder',
}

export const TAXES = 20;
export const DEFAULT_AGENCY_TAXES = 10;
