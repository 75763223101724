import React, { useState, useRef } from 'react';

import { Box, Button, Typography, Snackbar, Alert, Chip, Divider, Grid } from '@mui/material';
import { DirectorySearchResult } from 'store/types/directorySearch';
import { StyledAvatarWrap, StyledSocialWrap, StyledVerifiedIcon, StyledOGIcon } from '../styled';
import Avatar from 'components/Avatar';
import HighlightedView from 'components/DirectorySearch/HighlightedView';

interface IDirectorySearchItem {
  directorySearchResult: DirectorySearchResult;
  onAnonymousMode?: boolean;
}

const DirectorySearchAvatarView: React.FC<IDirectorySearchItem> = ({
  directorySearchResult,
  onAnonymousMode,
}) => {
  return (
    <StyledAvatarWrap>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Avatar
          size="medium"
          image={directorySearchResult.avatarUrl}
          firstName={directorySearchResult.firstName || directorySearchResult.initials || ''}
          lastName={directorySearchResult.lastName || undefined}
          isBlur={onAnonymousMode ? true : undefined}
        />
        <Typography
          variant={'body1'}
          sx={{ my: 1, textAlign: 'center', fontWeight: 500 }}
          display={{ xs: 'none', md: 'block' }}
        >
          {onAnonymousMode ? (
            directorySearchResult?.initials
          ) : directorySearchResult?.firstName || directorySearchResult?.lastName ? (
            <>
              {directorySearchResult?.highlights?.includes('firstName') ? (
                <HighlightedView text={directorySearchResult?.firstName || ''} />
              ) : (
                directorySearchResult?.firstName
              )}{' '}
              {directorySearchResult?.highlights?.includes('lastName') ? (
                <HighlightedView text={directorySearchResult?.lastName || ''} />
              ) : (
                directorySearchResult?.lastName
              )}
            </>
          ) : (
            directorySearchResult?.initials
          )}
        </Typography>

        <StyledSocialWrap mt={{ xs: 1.5, md: 0 }}>
          {directorySearchResult.isApproved && <StyledVerifiedIcon />}
          <StyledOGIcon />
        </StyledSocialWrap>
      </Box>
      {/* {directorySearchResult.rate && (
            <StyledStarChip
              icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
              label={`${rateFormat(directorySearchResult.rate?.totalRate)}/5`}
              variant="filled"
            />
          )} */}
    </StyledAvatarWrap>
  );
};

export default DirectorySearchAvatarView;
