import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" sx={{ fill: 'transparent' }}>
    <path
      d="M8.55 3.22344L16.775 11.4234C16.8583 11.5234 16.9167 11.6194 16.95 11.7114C16.9833 11.8028 17 11.8984 17 11.9984C17 12.0984 16.9833 12.1944 16.95 12.2864C16.9167 12.3778 16.8583 12.4734 16.775 12.5734L8.525 20.7984C8.39167 20.9484 8.225 21.0234 8.025 21.0234C7.825 21.0234 7.65 20.9401 7.5 20.7734C7.35 20.6234 7.275 20.4444 7.275 20.2364C7.275 20.0278 7.35 19.8568 7.5 19.7234L15.2 11.9984L7.475 4.27344C7.325 4.12344 7.25 3.95277 7.25 3.76144C7.25 3.56944 7.33333 3.39011 7.5 3.22344C7.65 3.07344 7.825 2.99844 8.025 2.99844C8.225 2.99844 8.4 3.07344 8.55 3.22344Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
