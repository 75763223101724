import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { Capacitor } from '@capacitor/core';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NoSsr from '@mui/material/NoSsr';
import Tooltip from '@mui/material/Tooltip';

import { EAuthType } from 'models/consts';

import { FeedbackIcon } from 'styles/icons/FeedbackIcon';

import AuthBackgroundAgency from 'styles/icons/AuthBackgroundAgency.svg';
import AuthBackgroundConsultant from 'styles/icons/AuthBackgroundConsultant.svg';
import AuthBackgroundCustomer from 'styles/icons/AuthBackgroundCustomer.svg';
import AuthBackgroundWelcome from 'styles/icons/AuthBackgroundWelcome.svg';
import AuthBackgroundXSNew from 'styles/icons/AuthBackgroundXSNew.svg';

import { StyledFab, StyledLayout, StyledLayoutPerson } from './styled';

import AuthHeader from 'components/Header/AuthHeader';
import FloatingFeedbackDialog from 'components/Modal/FloatingFeedbackDialog/FloatingFeedbackDialog';

import updateAction from 'store/actions/updateAction';
import useMatchMultiple from 'hooks/useMatchUrls';
import ROUTES from 'router/constants';

const AuthLayout: FunctionComponent = () => {
  const isMobileNative = Capacitor.isNativePlatform();

  const [searchParams] = useSearchParams();

  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const [bgImage, setBgImage] = useState<string>('');

  const { actions } = useStateMachine({ updateAction });

  const isMarginEnabled = useMatchMultiple([
    `${ROUTES.SIGN_UP}`,
    `${ROUTES.CUSTOMER_REGISTRATION}`,
  ]);

  useEffect(() => {
    actions.updateAction({ experiences: [], expertise: [] });
  }, [actions]);

  const preloadImage = (src: string) => {
    return new Promise<void>(resolve => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
    });
  };

  const renderAuthBG = useMemo(() => {
    switch (searchParams.get('type')) {
      case EAuthType.SELF_CUSTOMER:
      case EAuthType.CUSTOMER: {
        return AuthBackgroundCustomer;
      }
      case EAuthType.CONSULTANT: {
        return AuthBackgroundConsultant;
      }
      case EAuthType.AGENCY: {
        return AuthBackgroundAgency;
      }
      default: {
        return AuthBackgroundWelcome;
      }
    }
  }, [searchParams]);

  useEffect(() => {
    preloadImage(renderAuthBG).then(() => {
      setBgImage(`url(${renderAuthBG})`);
    });
  }, [renderAuthBG]);

  return (
    <NoSsr>
      <StyledLayout
        sx={{
          backgroundImage: {
            xs: `url(${AuthBackgroundXSNew})`,
            lg: bgImage,
          },
          ...(isMobileNative && {
            '@media (max-height: 600px)': {
              backgroundImage: 'none',
            },
          }),
        }}
      >
        <StyledLayoutPerson>
          <AuthHeader />
          <Box
            sx={{
              overflowY: 'scroll',
              mt: { xs: isMobileNative && isMarginEnabled ? 10 : 0, lg: 4 },
              flexGrow: 1,
            }}
          >
            <Container
              maxWidth="xxl"
              fixed
              sx={{
                height: '100%',
                pr: { xs: 2, sm: 3 },
                overflowX: { xs: 'hidden', sm: 'unset' },
              }}
            >
              <Outlet />
              <Tooltip title={'Leave Feedback'} arrow sx={{ display: { xs: 'none' } }}>
                <StyledFab
                  color="tertiary"
                  aria-label="add feedback"
                  onClick={() => setShowFeedbackDialog(true)}
                >
                  <FeedbackIcon />
                </StyledFab>
              </Tooltip>
            </Container>
          </Box>
        </StyledLayoutPerson>

        <FloatingFeedbackDialog
          visible={showFeedbackDialog}
          close={() => setShowFeedbackDialog(false)}
        />
      </StyledLayout>
    </NoSsr>
  );
};

export default AuthLayout;
