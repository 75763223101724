export enum EEnterprise {
  acceptUsersOrders = 'enterprise/acceptUsersOrders',
  addEnterpriseCompany = 'enterprise/addEnterpriseCompany',
  getPurchaseOrder = 'enterprise/getPurchaseOrder',
  getPurchaseOrders = 'enterprise/getPurchaseOrders',
  getPurchaseOrdersBalance = 'enterprise/getPurchaseOrdersBalance',
  changeUserRole = 'enterprise/changeUserRole',
  changeUserStatus = 'enterprise/changeUserStatus',
  checkInviteUser = 'enterprise/checkInviteUser',
  fetchUsersList = 'enterprise/fetchUsersList',
  searchUsersList = 'enterprise/searchUsersList',
  registrationUser = 'enterprise/registrationUser',
  registrationCustomer = 'enterprise/registrationCustomer',
  invitationUser = 'enterprise/invitationUser',
  resendInvitationUser = 'enterprise/resendInvitationUser',
  getInvoices = 'enterprise/getInvoices',
  fetchCompanyTeamsList = 'enterprise/fetchCompanyTeamsList',
  teamAccessRequest = 'enterprise/teamAccessRequest',
  approveAccessRequest = 'enterprise/approveAccessRequest',
  declineAccessRequest = 'enterprise/declineAccessRequest',
  addTeamMember = 'enterprise/addTeamMember',
  removeTeamMember = 'enterprise/removeTeamMember',
}
