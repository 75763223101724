import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const updateCuratedOrderInviteCustomer = createAsyncThunk(
  EOrder.updateCuratedOrderInviteCustomer,
  async (payload: any) => {
    const { data } = await instance.post('draft-orders/resend-invite', payload);

    return data;
  },
);

export default updateCuratedOrderInviteCustomer;
