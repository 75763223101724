import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MuiLink from '@mui/material/Link';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { ERole, RolesForTeamManaging } from 'models/consts';

import BootstrapInput from 'components/CustomFields/BootstrapInput';
import TableCell from 'components/TableCustomized/TableCell';
import TableRow from 'components/TableCustomized/TableRow';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getEnterpriseTeamsSelector } from 'store/selectors/getEnterpriseSelector';
import {
  getEnterpriseCustomerStatus,
  getEnterpriseCustomerClassName,
} from 'helpers/getEnterpriseCustomerStatus';
import isCustomerInvited from 'helpers/isCustomerInvited';

import { IBasicUser } from 'store/types/user';
import { ITeam } from 'store/types/teams';
import { IEnterpriseUser } from 'store/types/enterprise';

interface IEnterpriseUsersRowProps {
  // row: IEnterpriseUser;
  row: any;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
  onChangeTeam?: (
    event: SelectChangeEvent,
    { userId, oldTeamId }: { userId: number; oldTeamId?: number },
  ) => void;
  onReviewClick?: (row: any) => void;
}

const EnterpriseUsersRow: FunctionComponent<IEnterpriseUsersRowProps> = ({
  row,
  onChangeRole,
  onChangeStatus,
  onChangeTeam,
  onReviewClick,
}) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;
  const teamsList: ITeam[] = useAppSelector(getEnterpriseTeamsSelector);
  const isInvited = isCustomerInvited(row);

  return (
    <TableRow key={row.email}>
      <TableCell>
        <Typography fontWeight={500}>{row.email}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          className={getEnterpriseCustomerClassName(
            isInvited,
            row.isActive,
            !row.customer?.approveAccess && row.customer?.accessRequests?.length,
          )}
          label={getEnterpriseCustomerStatus(
            isInvited,
            row.isActive,
            !row.customer?.approveAccess && row.customer?.accessRequests?.length,
          )}
          variant="filled"
        />
      </TableCell>
      {me?.role.name === ERole.CUSTOMER_ADMIN && (
        <TableCell>
          <Box mr={{ xs: 0, md: 5, xxl: 10 }}>
            <MuiSelect
              displayEmpty
              label="Select team"
              labelId="select-team"
              input={<BootstrapInput />}
              value={(row.customer?.teams?.length && String(row.customer?.teams[0].id)) || ''}
              sx={{ height: 42, fontSize: '.875rem' }}
              onChange={event => {
                onChangeTeam &&
                  onChangeTeam(event, {
                    userId: row.id,
                    oldTeamId: row.customer?.teams?.length ? row.customer.teams[0].id : undefined,
                  });
              }}
            >
              {teamsList?.map(({ id, name }: { id: number; name: string }) => (
                <MenuItem key={name} value={id}>
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          </Box>
        </TableCell>
      )}
      <TableCell>
        <Box mr={{ xs: 0, md: 5, xxl: 10 }}>
          {row.role.name === ERole.CUSTOMER_ADMIN ? (
            <Typography>Customer Admin</Typography>
          ) : (
            <MuiSelect
              displayEmpty
              label="Select role"
              labelId="select-role"
              input={<BootstrapInput />}
              value={String(row.role.id) || ''}
              sx={{ textTransform: 'capitalize', height: 42, fontSize: '.875rem' }}
              onChange={event => {
                onChangeRole(event, row.id);
              }}
              disabled={
                (me.role.name !== ERole.CUSTOMER_ADMIN &&
                  me.role.name !== ERole.CUSTOMER_TEAM_ADMIN) ||
                me.id === row.id ||
                row.isActive === null ||
                row.role.name === ERole.CUSTOMER_ADMIN
              }
            >
              {RolesForTeamManaging.map(({ id, name }: { id: number; name: string }) => (
                <MenuItem key={name} sx={{ textTransform: 'capitalize' }} value={id}>
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        </Box>
      </TableCell>
      <TableCell>
        {onReviewClick && !!row.customer?.accessRequests?.length && me.id !== row.id && (
          <MuiLink
            color="secondary"
            onClick={() => onReviewClick(row)}
            sx={{ display: 'inline-flex' }}
            underline="none"
          >
            <PlayArrowOutlinedIcon />
            <Typography component="span" lineHeight="20px" ml={1}>
              Review
            </Typography>
          </MuiLink>
        )}
        {!row.customer?.accessRequests?.length &&
          !isInvited &&
          row.isActive &&
          me.id !== row.id &&
          (me.role.name === ERole.CUSTOMER_ADMIN ||
            (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
              row.role.name !== ERole.CUSTOMER_ADMIN)) && (
            <MuiLink
              color="secondary"
              onClick={() => onChangeStatus(row, 'active')}
              sx={{ display: 'inline-flex' }}
              underline="none"
            >
              <PauseCircleIcon />
              <Typography component="span" lineHeight="20px" ml={1}>
                Pause
              </Typography>
            </MuiLink>
          )}
        {!isInvited &&
          !row.isActive &&
          me.id !== row.id &&
          (me.role.name === ERole.CUSTOMER_ADMIN ||
            (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
              row.role.name !== ERole.CUSTOMER_ADMIN)) && (
            <MuiLink
              color="secondary"
              onClick={() => onChangeStatus(row, 'deactive')}
              sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
              underline="none"
            >
              <PowerIcon />
              <Typography component="span" lineHeight="20px" ml={1}>
                Activate
              </Typography>
            </MuiLink>
          )}
        {isInvited &&
          me.id !== row.id &&
          (me.role.name === ERole.CUSTOMER_ADMIN ||
            (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
              row.role.name !== ERole.CUSTOMER_ADMIN)) && (
            <MuiLink
              color="secondary"
              onClick={() => onChangeStatus(row, 'refresh')}
              sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
              underline="none"
            >
              <RefreshIcon />
              <Typography component="span" lineHeight="normal" ml={1}>
                Resend
              </Typography>
            </MuiLink>
          )}
      </TableCell>
    </TableRow>
  );
};

export default EnterpriseUsersRow;
