import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface IStyledBase {
  children?: any;
  className?: string;
  size?: string;
  round?: boolean;
  backgroundColor?: string;
}

interface IStyledImage extends IStyledBase {
  alt: string;
  isBlur?: boolean;
  src: string;
  title: string;
  unique: string;
}
interface IStyledBox extends BoxProps {
  unique: string;
}

const StyledRootBase = styled('div')<IStyledBase>(({ round }) => ({
  overflow: 'hidden',
  borderRadius: (round && '50%') || 'none',
  '&.large': {
    height: 104,
    width: 104,
    flex: '0 0 104px',
  },
  '&.small': {
    height: 48,
    width: 48,
    flex: '0 0 48px',
    fontSize: '0.875em',
  },
  '&.xs-small': {
    height: 35,
    width: 35,
    flex: '0 0 35px',
    fontSize: '0.875em',
  },
  '&.medium': {
    height: 60,
    width: 60,
    flex: '0 0 60px',
  },
  '&.custom-small': {
    height: 24,
    width: 24,
    flex: '0 0 24px',
    fontSize: '0.875em',
  },
  '&.pre-large': {
    height: 95,
    width: 95,
    flex: '0 0 95px',
  },
}));

const StyledAvatarBase = styled('img')<IStyledImage>(({ isBlur, round, unique }) => ({
  ...(!round && {
    WebkitClipPath: `url(#${unique})`,
    clipPath: `url(#${unique})`,
    backgroundSize: 'cover',
  }),
  width: '100%',
  height: '100%',
  '&.large': {
    height: 104,
    width: 104,
    flex: '0 0 104px',
  },
  '&.small': {
    height: 48,
    width: 48,
    flex: '1 0 48px',
    fontSize: '0.875em',
  },
  '&.xs-small': {
    height: 35,
    width: 35,
    flex: '1 0 35px',
    fontSize: '0.875em',
  },
  '&.medium': {
    height: 60,
    width: 60,
    flex: '0 0 60px',
  },
  '&.custom-small': {
    height: 24,
    width: 24,
    flex: '0 0 24px',
    fontSize: '0.875em',
  },
  '&.pre-large': {
    height: 95,
    width: 95,
    flex: '0 0 95px',
  },
  ...(isBlur && {
    filter: 'blur(3px)',
  }),
}));

const StyledText = styled(Box)<IStyledBox>(({ unique }) => ({
  WebkitClipPath: `url(#${unique})`,
  clipPath: `url(#${unique})`,
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
  '& svg': {
    color: 'white',
    width: '50%',
  },
}));

const StyledCompanyText = styled(StyledText)({
  backgroundColor: '#F5F5F5',
  color: '#2C2D2E',
  '& svg': {
    color: '#D4D4D4',
    width: '50%',
    height: '50%',
    fill: 'transparent',
  },
});

interface IStyledText {
  backgroundColor?: string;
}

const StyledUserText = styled(StyledText)<IStyledText>(({ backgroundColor, theme }) =>
  theme.unstable_sx({
    backgroundColor: backgroundColor || '#1F1F1F',
    color: 'primary.main',
    '& svg': {
      color: 'white',
      width: '50%',
    },
  }),
);

const StyledSvg = styled('svg')({
  position: 'absolute',
  width: 0,
  height: 0,
});

export {
  StyledAvatarBase,
  StyledCompanyText,
  StyledRootBase,
  StyledSvg,
  StyledText,
  StyledUserText,
};
