import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Controller, useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import {
  categoryProductsSelector,
  expertiseLevelsSelector,
  modulesSelector,
  productPricesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';
import { TIMELINE_HOURS_NEEDED } from 'models/consts';

import { Autocomplete, Grid, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ProductsModulesWork from 'components/CustomFields/ProductsModulesWork';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { BootstrapInput } from 'components/CustomFields';
import DelayedPopper from 'components/OrderCreationComponents/Timeline/DelayedPopper';
import { Select } from 'components/CustomFields';

import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import { TAXES } from 'store/constants';

interface ICuratedOrderScopeProps {
  isEdit: boolean;
  disableAllFields?: boolean;
  isOrderEdit: any;
}

const CuratedOrdersScope: FunctionComponent<ICuratedOrderScopeProps> = ({
  isEdit,
  disableAllFields,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const isMobileNative = Capacitor.isNativePlatform();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const {
    clearErrors,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const {
    product,
    totalHours,
    searchEndDate,
    searchStartDate,
    scheduleType,
    customerRate,
    isCustomerRateDefault,
    specialistRate,
    isSpecialistRateDefault,
    levelId,
  } = watch();

  const defaultProductRef = useRef(null);

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  const seniorities = useAppSelector(expertiseLevelsSelector);
  const productPrices = useAppSelector(productPricesSelector(product?.id));

  useEffect(() => {
    if (!categoriesWithProductsList?.length || !modulesList?.length || !worksList.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
    dispatch(fetchExpertiseLevels());
  }, [categoriesWithProductsList, modulesList, worksList, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(fetchProductPrices(product?.id));
    }
  }, [product, dispatch]);

  useEffect(() => {
    if (isEdit) return;
    if (!productPrices) return;
    const updateRates = (priceData: any) => {
      const coefficient = (100 - TAXES) / 100;
      const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
      setValue('customerRate', priceData.stripePriceCents / 100);
      setValue('specialistRate', specialistPrice);
    };
    const defaultPriceData = productPrices.find(price => price.levelId === 2);
    if (defaultPriceData) {
      updateRates(defaultPriceData);
    }
    if (levelId) {
      const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
      if (levelSpecificPriceData) {
        updateRates(levelSpecificPriceData);
      }
    }
  }, [productPrices, levelId, setValue, isEdit]);

  useEffect(() => {
    if (product) {
      setValue('levelId', 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, setValue, isEdit]);

  useEffect(() => {
    if (isInitialLoad && product) {
      defaultProductRef.current = product;
      if ((isCustomerRateDefault || isSpecialistRateDefault) && product) {
        if (!productPrices) return;
        const updateRates = (priceData: any) => {
          const coefficient = (100 - TAXES) / 100;
          const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
          if (isCustomerRateDefault) {
            setValue('customerRate', priceData.stripePriceCents / 100);
          }
          if (isSpecialistRateDefault) {
            setValue('specialistRate', specialistPrice);
          }
        };
        const defaultPriceData = productPrices.find(price => price.levelId === 2);
        if (defaultPriceData) {
          updateRates(defaultPriceData);
        }
        if (levelId) {
          const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
          if (levelSpecificPriceData) {
            updateRates(levelSpecificPriceData);
          }
        }
      }
      setIsInitialLoad(false);
      return;
    }
    if (product && product !== defaultProductRef.current) {
      if (!productPrices) return;
      const updateRates = (priceData: any) => {
        const coefficient = (100 - TAXES) / 100;
        const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);
        setValue('customerRate', priceData.stripePriceCents / 100);
        setValue('specialistRate', specialistPrice);
        setValue('isCustomerRateDefault', true);
        setValue('isSpecialistRateDefault', true);
      };
      const defaultPriceData = productPrices.find(price => price.levelId === 2);
      if (defaultPriceData) {
        updateRates(defaultPriceData);
      }
      if (levelId) {
        const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
        if (levelSpecificPriceData) {
          updateRates(levelSpecificPriceData);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productPrices]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ mr: 2, mt: 1, mb: 2, display: 'flex', flexDirection: 'column', paddingRight: 1 }}
    >
      <Grid width={'100%'} maxWidth={'97%'} ml={2}>
        <ProductsModulesWork disableAllFields={disableAllFields} />
      </Grid>
      <Grid
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <Grid
          xs={12}
          md={6}
          lg={4}
          mr={!isiPadOrMobile ? 1 : undefined}
          mt={isiPadOrMobile ? -1 : undefined}
        >
          <InputLabel>Schedule Type</InputLabel>
          <Controller
            control={control}
            name="scheduleType"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                disabled={disableAllFields}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                onOpen={() => setValue('scheduleType', '')}
                field={field}
                handleChange={event => setValue('scheduleType', event)}
                // isMainTag
                options={[
                  { id: 1, name: 'Weekly Commitment' },
                  { id: 2, name: 'Number of Hours' },
                ]}
                placeholder="Select Schedule Type"
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          mr={!isiPadOrMobile ? 1.5 : undefined}
          mt={isiPadOrMobile ? 2 : undefined}
        >
          <InputLabel>Total Hours</InputLabel>
          <Controller
            control={control}
            name="totalHours"
            render={({ field }) => (
              <Autocomplete
                {...field}
                disabled={disableAllFields}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                onChange={(_, value) => {
                  field.onChange(parseInt(value));
                  setValue('totalHours', value);
                }}
                options={TIMELINE_HOURS_NEEDED}
                PopperComponent={(isMobileNative && DelayedPopper) || undefined}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    value={totalHours}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      const isValidInput = /^[1-9]\d*$/.test(value);
                      if ((isValidInput || value === '') && value <= 5000) {
                        field.onChange(parseInt(value));
                        setValue('totalHours', parseInt(value));
                      } else {
                        setError('totalHours', { message: 'Value cannot be more than 5000' });
                      }
                    }}
                    data-test="weeklyHoursField"
                    error={!!errors.allHours}
                    helperText={errors.allHours?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} mt={isiPadOrMobile ? 2 : undefined}>
          <InputLabel>Seniority Level</InputLabel>
          <Controller
            control={control}
            name="levelId"
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                disabled={disableAllFields}
                inputRef={ref}
                label="Select Level"
                options={seniorities || []}
                labelMenuItem="name"
                arrowIconSx={{ right: '10px' }}
                isCuratedOrder={true}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        mt={isiPadOrMobile ? 1 : 3}
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <InputLabel>Additional Requirements:</InputLabel>
        <Controller
          control={control}
          name="additionalRequirements"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              type="text"
              multiline={true}
              rows={1}
              disabled={disableAllFields}
              placeholder="Interview Required: Yes
                Number of Specialists: 1
                Additional Background Check: Details
                Customer availability requirements: full time"
              sx={{
                width: '100%',
              }}
              onChange={(event: any) => {
                const value = event.target.value;
                field.onChange(value);
                setValue('additionalRequirements', value);
              }}
              autoComplete="off"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CuratedOrdersScope;
