import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import ROUTES from 'router/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CuratedOrderDetails from 'components/CuratedOrders/CuratedOrderDetails';

import ArrowLeftIcon from 'styles/icons/ArrowLeft';
import CuratedOrdersScope from 'components/CuratedOrders/CuratedOrdersScope';
import { IBasicOrders, IFullOrder } from 'store/types/order';
import { calcWeeks } from 'utils/dateFormatter';
import createCuratedOrderDraft from 'store/query/curated-order/createCuratedOrderDraft';
import updateCuratedOrderDraft from 'store/query/curated-order/updateCuratedOrderDraft';
import ModalWrapper from 'components/Modal/Modal';
import { AssigmentTurnedInIcon } from 'styles/icons/AssigmentTurnedInIcon';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { ERole } from 'models/consts';
import curatedOrderInviteCustomer from 'store/query/curated-order/curatedOrderInviteCustomer';
import updateCuratedOrderInviteCustomer from 'store/query/curated-order/updateCuratedOrderInviteCustomer';
import fetchUsersList from 'store/query/user/fetchUsersList';

const CuratedCreateOrder: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isOrderEdit = location?.state?.order;
  const theme = useTheme();

  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isWeeklyCommitment = isOrderEdit?.timeLine?.flexibleHours;

  const [showInviteCustomerModal, setShowInviteCustomerModal] = useState<boolean>(false);

  const user = useAppSelector(getMeSelector);

  const methods: any = useForm({
    mode: 'onChange',
    defaultValues: {
      salesManager: isOrderEdit ? isOrderEdit.userId : user.id,
      customerType: isOrderEdit?.customerEmail ? 'new_customer' : 'existing_customer',
      customerEmail: isOrderEdit?.customerEmail ? isOrderEdit.customerEmail : undefined,
      customer: isOrderEdit?.customer ? isOrderEdit.customer : undefined,
      companyId: isOrderEdit?.companyId ? isOrderEdit.companyId : undefined,
      companyName: isOrderEdit?.companyName ? isOrderEdit.companyName : undefined,
      specialist: isOrderEdit?.worker ? isOrderEdit.worker : undefined,
      orderName: isOrderEdit?.name ? isOrderEdit.name : '',
      description: isOrderEdit?.description ? isOrderEdit.description : '',
      customerRate: isOrderEdit?.customerRate ? isOrderEdit.customerRate / 100 : '',
      isCustomerRateDefault: isOrderEdit?.customerRate ? false : true,
      specialistRate: isOrderEdit?.specialistRate ? isOrderEdit.specialistRate / 100 : '',
      isSpecialistRateDefault: isOrderEdit?.specialistRate ? false : true,
      payoutTerms: isOrderEdit?.payoutTerms ? isOrderEdit.payoutTerms : 15,
      levelId: isOrderEdit?.levelId ? isOrderEdit.levelId : '',
      modules: isOrderEdit?.expertise?.modules ? isOrderEdit?.expertise?.modules : [],
      works: isOrderEdit?.expertise?.works ? isOrderEdit?.expertise?.works : [],
      product: isOrderEdit?.expertise?.product ? isOrderEdit?.expertise?.product : '',
      scheduleType: isOrderEdit?.timeLine
        ? !isWeeklyCommitment
          ? { id: 1, name: 'Weekly Commitment' }
          : { id: 2, name: 'Number of Hours' }
        : '',
      totalHours: isOrderEdit?.timeLine?.allHours
        ? isOrderEdit?.timeLine?.flexibleHours
          ? parseInt(isOrderEdit?.timeLine?.allHours)
          : parseInt(isOrderEdit?.timeLine?.availabilityWeek)
        : undefined,
      searchStartDate: isOrderEdit?.timeLine?.startDate
        ? dayjs(isOrderEdit?.timeLine?.startDate).utc().format('YYYY-MM-DD')
        : '',
      searchEndDate: isOrderEdit?.timeLine?.endDate
        ? dayjs(isOrderEdit?.timeLine?.endDate).utc().format('YYYY-MM-DD')
        : '',
      additionalRequirements: isOrderEdit?.additionalRequirements
        ? isOrderEdit.additionalRequirements
        : '',
    },
  });

  const {
    salesManager,
    orderName,
    customer,
    customerEmail,
    specialist,
    description,
    customerRate,
    isCustomerRateDefault,
    specialistRate,
    isSpecialistRateDefault,
    modules,
    works,
    product,
    levelId,
    scheduleType,
    totalHours,
    searchStartDate,
    searchEndDate,
    payoutTerms,
    additionalRequirements,
    companyId,
    companyName,
    customerType,
  } = methods.watch();

  const prepPayload = () => {
    const weeks = calcWeeks(searchEndDate, searchStartDate);
    const availabilityWeek = scheduleType?.id === 2 ? Math.ceil(totalHours / weeks) : totalHours;
    const allHours = scheduleType?.id === 1 ? weeks * availabilityWeek : totalHours;
    const email = customerType === 'new_customer' ? customerEmail : undefined;
    const existingCustomerId = customerEmail ? undefined : customer?.id;

    const payload: any = {
      userId: salesManager ? salesManager.id : undefined,
      companyId: companyId ? companyId : undefined,
      customerId: existingCustomerId,
      customerEmail: email,
      companyName: companyName,
      description: description || undefined,
      name: orderName,
      levelId: levelId || undefined,
      timeLine:
        searchEndDate || allHours || searchStartDate
          ? {
              startDate: searchStartDate || undefined,
              endDate: searchEndDate || undefined,
              allHours: allHours || undefined,
              availabilityWeek: availabilityWeek || undefined,
              flexibleHours: scheduleType?.id === 1 ? false : true,
            }
          : undefined,
      expertise: product
        ? {
            productId: product.id,
            modules: modules.map((item: any) => ({
              moduleId: item.id,
              isPrimary: modules.primaryModule === item.id,
            })),
            works: works.map((item: any) => ({
              workId: item.id,
              isPrimary: works.primaryWorkType === item.id,
            })),
          }
        : undefined,
      workerId: specialist?.id || undefined,
      customerRate: !isCustomerRateDefault ? customerRate * 100 : undefined,
      specialistRate: !isSpecialistRateDefault ? specialistRate * 100 : undefined,
      payoutTerms: payoutTerms,
      additionalRequirements: additionalRequirements,
    };
    return payload;
  };

  const onSubmit = () => {
    if (isOrderEdit) {
      dispatch(
        updateCuratedOrderDraft({
          id: isOrderEdit.id,
          ...prepPayload(),
        }),
      )
        .unwrap()
        .then(response => {
          if (response) {
            navigate(ROUTES.CURATED_ORDERS_LANDING);
          }
        });
    } else {
      dispatch(createCuratedOrderDraft(prepPayload()))
        .unwrap()
        .then(response => {
          if (response) {
            navigate(ROUTES.CURATED_ORDERS_LANDING);
          }
        });
    }
  };

  const onInviteCustomer = () => {
    if (isOrderEdit && isOrderEdit.customerId) {
      dispatch(
        updateCuratedOrderDraft({
          id: isOrderEdit.id,
          ...prepPayload(),
        }),
      )
        .unwrap()
        .then(response => {
          if (response) {
            dispatch(updateCuratedOrderInviteCustomer({ id: response.id }))
              .unwrap()
              .then(response => {
                setShowInviteCustomerModal(!showInviteCustomerModal);
                navigate(ROUTES.CURATED_ORDERS_LANDING);
              });
          }
        });
    } else {
      dispatch(
        isOrderEdit
          ? updateCuratedOrderDraft({
              id: isOrderEdit.id,
              ...prepPayload(),
            })
          : createCuratedOrderDraft(prepPayload()),
      )
        .unwrap()
        .then(response => {
          if (response) {
            dispatch(curatedOrderInviteCustomer({ id: response.id }))
              .unwrap()
              .then(response => {
                setShowInviteCustomerModal(!showInviteCustomerModal);
                navigate(ROUTES.CURATED_ORDERS_LANDING);
              });
          }
        });
    }
  };

  const isCustomerInfoUpdated = () => {
    if (isOrderEdit && isOrderEdit.customerId) {
      return isOrderEdit.customerEmail !== customerEmail || isOrderEdit.companyName !== companyName;
    }
    return false;
  };

  return (
    <FormProvider {...methods}>
      <Box mt={isiPadOrMobile ? 1 : 2}>
        <Grid mb={isiPadOrMobile ? 0 : 4}>
          <Button
            color="secondary"
            onClick={() => navigate(ROUTES.CURATED_ORDERS_LANDING)}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="outlined"
          >
            Back to orders
          </Button>
          <IconButton
            onClick={() => navigate(ROUTES.CURATED_ORDERS_LANDING)}
            sx={{ display: { md: 'none' }, ml: -2.5 }}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Grid>
        <Typography
          mb={{ xs: 2, md: 3 }}
          sx={{
            textTransform: 'capitalize',
            fontSize: isiPadOrMobile ? '30px' : '40px',
            fontWeight: 500,
          }}
          alignSelf="center"
        >
          Create Draft Orders
        </Typography>

        <CuratedOrderDetails
          disableAllFields={user.role.name === ERole.TALENT}
          isOrderEdit={isOrderEdit}
        />

        <Typography sx={{ fontSize: isiPadOrMobile ? '25px' : '30px', fontWeight: 500 }}>
          Scope
        </Typography>
        <Typography mb={2} sx={{ fontSize: isiPadOrMobile ? '14px' : '18px', fontWeight: 400 }}>
          Please define the expertise needed and time schedule
        </Typography>

        <CuratedOrdersScope
          isEdit={isOrderEdit ? true : false}
          isOrderEdit={isOrderEdit}
          disableAllFields={user.role.name === ERole.TALENT}
        />
        <Grid mt={4} mb={4} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            disabled={!orderName || user.role.name === ERole.TALENT}
            // loading={isSubmitting}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            data-test="save-sales-create-order"
            onClick={onSubmit}
            sx={{ width: isiPadOrMobile ? '100%' : '10%', marginRight: 1 }}
          >
            {isOrderEdit ? 'Update' : 'Save'}
          </LoadingButton>

          <LoadingButton
            disabled={
              // !userId ||
              !orderName ||
              !customerEmail ||
              !specialist ||
              !description ||
              !customerRate ||
              !specialistRate ||
              !modules ||
              !works ||
              !product ||
              !levelId ||
              !scheduleType ||
              !totalHours ||
              !searchStartDate ||
              !searchEndDate ||
              !payoutTerms ||
              !companyName
            }
            // loading={isSubmitting}
            color="secondary"
            fullWidth
            type="submit"
            variant="contained"
            data-test="invite-new-for-sales-create-order"
            onClick={() => setShowInviteCustomerModal(!showInviteCustomerModal)}
            sx={{ width: isiPadOrMobile ? '100%' : '15%' }}
          >
            {!isOrderEdit?.customerId
              ? 'Invite Customer'
              : isCustomerInfoUpdated()
                ? 'Update Invitation'
                : 'Resend Invitation'}
          </LoadingButton>
        </Grid>
        <ModalWrapper
          dialogProps={{
            open: showInviteCustomerModal,
            onClose: () => setShowInviteCustomerModal(false),
          }}
        >
          <Box display={'flex'} alignItems={'center'} mb={5} flexDirection={'column'}>
            <Box mb={2}>
              <AssigmentTurnedInIcon />
            </Box>
            <Typography fontSize={16} fontWeight={400}>
              Confirm your action
            </Typography>
            <Typography
              mt={2}
              mb={2}
              fontSize={32}
              fontWeight={500}
              textAlign={'center'}
              width={isiPadOrMobile ? '90%' : '80%'}
            >
              {isCustomerInfoUpdated()
                ? 'Customer information has changed, do you want to resend the invitation?'
                : 'Are you sure you want to send the invitation to the Customer?'}
            </Typography>

            <Button
              data-test="delete-cancel-button-md"
              color="secondary"
              onClick={onInviteCustomer}
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: 'white',
                width: isiPadOrMobile ? '70%' : '40%',
              }}
              variant="contained"
            >
              {isOrderEdit && isOrderEdit.customerId ? 'Resend Invitation' : 'Send Invitation'}
            </Button>
          </Box>
        </ModalWrapper>
      </Box>
    </FormProvider>
  );
};

export default CuratedCreateOrder;
