import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import { ConfirmIcon } from 'styles/icons/ConfirmIcon';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import earlyOrderComplete from 'store/query/order/earlyOrderComplete';
import changeOrderStatus from 'store/query/order/changeOrderStatus';
import {
  getMeRoleSelector,
  isAgencyAdministratorsSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';

import ModalWrapper from '../Modal';
import { IBasicOrders, IFullOrders } from 'store/types/order';

interface IMarkAsCompletedModalProps {
  order: IBasicOrders | IFullOrders;
  onClose: () => void;
  open: boolean;
}
const MarkAsCompletedModal: FunctionComponent<IMarkAsCompletedModalProps> = ({
  order,
  onClose,
  open,
}) => {
  const dispatch = useAppDispatch();

  const isConsultant = useAppSelector(isConsultantSelector);
  const isAgencyAdministrators = useAppSelector(isAgencyAdministratorsSelector);
  const role = useAppSelector(getMeRoleSelector);

  const completeOrder = () => {
    if (isConsultant || isAgencyAdministrators) {
      dispatch(earlyOrderComplete({ ids: [order.id] }))
        .unwrap()
        .then(onClose);
    } else {
      dispatch(
        changeOrderStatus({
          id: order.id,
          status: 'completed',
          role,
          isPurchase: !!order.purchaseOrder,
        }),
      )
        .unwrap()
        .then(onClose);
    }
  };

  return (
    <ModalWrapper
      dialogProps={{
        open,
        onClose,
      }}
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box sx={{ width: 66, height: 66, mx: 'auto', mb: 3.5 }}>
          <ConfirmIcon />
        </Box>
        <Typography variant="h1" mb={2.5}>
          Confirm your action
        </Typography>
        <Typography mb={2}>Are you sure you want to mark the order as completed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" fullWidth onClick={completeOrder} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default MarkAsCompletedModal;
