import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Conversation, ConversationUpdateReason, Message } from '@twilio/conversations';

import dayjs from 'utils/dayjs';
import { ellipsize } from 'utils/textFormatter';

import { Theme } from '@mui/material/styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';

import { useAppSelector } from 'store/hooks';
import { IChatItem } from 'store/types/chat';

import { getMeSelector } from 'store/selectors/getUserSelector';

import Avatar from 'components/Avatar';
import {
  StyledBoxDate,
  StyledLastMessage,
  StyledListItem,
  StyledTypographyCount,
  StyledTypographyName,
} from './styled';
import { currentChatSelector } from '../../../store/selectors/getChatListSelector';
import { ERoleId } from '../../../models/consts';

interface IConversationsListItem {
  item: IChatItem;
  conversationItem: Conversation | undefined;
}

interface MessageExtend extends Message {
  attributes: any;
}
const ConversationsListItem: React.FunctionComponent<IConversationsListItem> = ({
  item,
  conversationItem,
}) => {
  const navigate = useNavigate();
  const { room } = useParams();

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const me = useAppSelector(getMeSelector);
  const currentChat = useAppSelector(currentChatSelector(room));

  const companion = useMemo(() => {
    if ([ERoleId.CUSTOMER_ADMIN, ERoleId.CUSTOMER].includes(me.role.id)) {
      const workerUser = item?.order?.agency || item.order?.worker || item.preOrder?.agency;
      return {
        ...workerUser,
        avatarUrl:
          item?.order?.agency?.logo ||
          item?.preOrder?.agency?.logo ||
          item?.order?.worker?.consultant.avatarUrl,
      } as any;
    } else {
      const customerUser = item.order?.customer || item.preOrder?.customer;
      return {
        ...customerUser,
        avatarUrl:
          item.order?.customer?.customer?.companies[0]?.logo ||
          item.preOrder?.customer?.customer?.companies[0]?.logo,
      } as any;
    }
  }, [me.role.id, item.order, item.preOrder]);

  const [lastMessage, setLastMessage] = useState<any>();

  const nameText = `${companion.firstName || companion.name} ${companion.lastName ?? ''}`;
  const nameTextEllipsis = ellipsize(nameText, 27);

  const updatedConversationListener = useCallback(
    ({ updateReasons }: { updateReasons: ConversationUpdateReason[] }) => {
      const updatedIndex = updateReasons.includes('lastReadMessageIndex');

      if (updatedIndex) {
        setLastMessage((s: any) => ({
          ...s,
          unreadCount: 0,
        }));
      }
    },
    [],
  );

  const addedMessageListener = useCallback(
    async (message: MessageExtend, conversationItem: Conversation) => {
      const text = message.body; // Текст сообщения
      const createdAt = message.dateCreated; // Дата создания сообщения
      const unreadCount = await conversationItem?.getUnreadMessagesCount();

      if (message.attributes?.email !== me.email) {
        setLastMessage({
          body: text || '',
          dateCreated: createdAt,
          unreadCount: unreadCount ?? (conversationItem?.lastMessage?.index || 0) + 1,
        });
      } else {
        setLastMessage({
          body: text || '',
          dateCreated: createdAt,
          unreadCount: 0,
        });
      }
    },
    [me.email],
  );

  useEffect(() => {
    if (conversationItem) {
      conversationItem.on('updated', updatedConversationListener);
      conversationItem.on('messageAdded', message =>
        addedMessageListener(message, conversationItem),
      );

      conversationItem.getMessages(1).then((messagePaginator: any) => {
        if (messagePaginator?.items[0]) {
          const unreadCount =
            messagePaginator?.items[0].conversation?.lastMessage.index -
            (messagePaginator?.items[0].conversation?.lastReadMessageIndex ?? -1);

          setLastMessage({
            body: messagePaginator?.items[0]?.body || '',
            dateCreated: messagePaginator?.items[0]?.dateCreated,
            unreadCount,
          });
        }
      });
    }
    return () => {
      if (conversationItem) {
        conversationItem.off('updated', updatedConversationListener);
        conversationItem.off('messageAdded', message =>
          addedMessageListener(message, conversationItem),
        );
      }
    };
  }, [addedMessageListener, conversationItem, updatedConversationListener]);

  return (
    <StyledListItem
      selected={(room && +room === item.id) || false}
      onClick={() => navigate(item.id.toString())}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar
          image={companion.avatarUrl}
          firstName={companion.firstName || companion.name || ''}
          lastName={companion.lastName || ''}
          size="small"
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <StyledTypographyName variant="body1">
            <b>{(hidden && nameTextEllipsis) || nameText}</b>
          </StyledTypographyName>
        }
        secondary={
          <>
            <Typography component="span" color="text.secondary" sx={{ display: 'block' }}>
              {(item.order?.hashCode && `Order #${item.order?.hashCode}`) ||
                (item.preOrder?.id && `Pre Order #${item.preOrder?.id}`)}
            </Typography>
            <StyledLastMessage component="span" gutterBottom color="text.primary">
              {lastMessage?.body}
            </StyledLastMessage>
          </>
        }
      />
      <StyledBoxDate my={3 / 4}>
        <Typography
          variant="body1"
          color={(room && +room === item.id && 'common.white') || 'text.secondary'}
        >
          {lastMessage && dayjs(lastMessage.dateCreated).format('HH:mm')}
        </Typography>
        {lastMessage?.unreadCount > 0 && (
          <StyledTypographyCount variant="body1">
            <strong>{lastMessage.unreadCount}</strong>
          </StyledTypographyCount>
        )}
      </StyledBoxDate>
    </StyledListItem>
  );
};

export default ConversationsListItem;
