import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from '../../middlewares/api';

import { EFlexSearch } from 'store/constants/flex-search';
import { DirectorySearchResponse } from 'store/types/directorySearch';
import { RootState } from 'store/store';
import { resetDirectorySearch } from 'store/reducers/directory-search';

const directorySearchQuery = createAsyncThunk<
  AxiosResponse<DirectorySearchResponse>,
  { values: any; doPaginate: boolean | undefined }
>(EFlexSearch.directorySearch, async ({ values, doPaginate }, { getState, dispatch }) => {
  const dirSearchState = (getState() as RootState).directorySearch;
  const limit = dirSearchState.limit;
  let offset = dirSearchState.offset;

  if (doPaginate) {
    offset += limit;
  } else {
    dispatch(resetDirectorySearch());
  }

  let url: string = `flexsearch/directory?`;

  const searchQuery: Record<string, any> = Object.fromEntries(
    Object.entries(values).filter(
      ([_, value]) =>
        value !== 0 &&
        value !== null &&
        value !== undefined &&
        value !== '' &&
        (!Array.isArray(value) ||
          (value.length > 0 && value.every(item => typeof item !== 'object'))) &&
        (typeof value !== 'object' || Array.isArray(value)),
    ),
  );

  if (searchQuery) {
    const params = new URLSearchParams(searchQuery);

    const urlQueryStr = params.toString();

    url += `${urlQueryStr}&`;
  }

  url += `limit=${limit}&offset=${offset}`;

  const resp = await instance.get(url);

  resp.data.newOffset = offset;

  return resp;
});

export default directorySearchQuery;
