import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" sx={{ fill: 'transparent' }}>
    <path
      d="M15.45 20.7766L7.225 12.5766C7.14167 12.4766 7.08333 12.3806 7.05 12.2886C7.01667 12.1972 7 12.1016 7 12.0016C7 11.9016 7.01667 11.8056 7.05 11.7136C7.08333 11.6222 7.14167 11.5266 7.225 11.4266L15.475 3.20156C15.6083 3.05156 15.775 2.97656 15.975 2.97656C16.175 2.97656 16.35 3.0599 16.5 3.22656C16.65 3.37656 16.725 3.55556 16.725 3.76356C16.725 3.97223 16.65 4.14323 16.5 4.27656L8.8 12.0016L16.525 19.7266C16.675 19.8766 16.75 20.0472 16.75 20.2386C16.75 20.4306 16.6667 20.6099 16.5 20.7766C16.35 20.9266 16.175 21.0016 15.975 21.0016C15.775 21.0016 15.6 20.9266 15.45 20.7766Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
