import React from 'react';

import { EMatchingResultType } from 'models/consts';
import { IMatchedCard } from 'store/types/consultant';
import updateAction from 'store/actions/updateAction';

import ConsultantView from 'views/Recommendation/ConsultantView';
import AgencyView from 'views/Recommendation/AgencyView';
import { useStateMachine } from 'little-state-machine';

interface IRecommendationPreviewModal {
  matchedCard: IMatchedCard;
  onSelectPreview: (worker: any) => void;
  isSalesOrTalent?: boolean;
  onAnonymousMode?: boolean;
}

const RecommendationPreviewModal: React.FunctionComponent<IRecommendationPreviewModal> = ({
  matchedCard,
  onSelectPreview,
  isSalesOrTalent,
  onAnonymousMode,
}) => {
  const {
    state: { worker },
  } = useStateMachine({ updateAction });

  if (matchedCard.cardType === EMatchingResultType.AGENCY) {
    return (
      <AgencyView
        onSelectPreview={onSelectPreview}
        agency={{
          ...matchedCard.agency,
          matchedConsultants: matchedCard.matchedConsultants,
        }}
        isSelectedExpert={worker?.agency?.id === matchedCard.agency?.id}
        isSalesOrTalent={isSalesOrTalent}
        onAnonymousMode={onAnonymousMode}
      />
    );
  }
  return (
    <ConsultantView
      {...matchedCard}
      isBlur
      onSelectPreview={onSelectPreview}
      isSelectedExpert={worker?.specialist?.id === matchedCard.specialist?.id}
      isSalesOrTalent={isSalesOrTalent}
      onAnonymousMode={onAnonymousMode}
    />
  );
};

export default RecommendationPreviewModal;
