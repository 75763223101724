import React, { FunctionComponent, useState, SyntheticEvent, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import dayjs from 'utils/dayjs';

import {
  Box,
  Button,
  Switch,
  Typography,
  InputLabel,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { LocationInput, Select } from 'components/CustomFields';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import AvailableFilters from 'views/ChatAssistantPage/AvailableFilters';
import ProductAutoComplete from 'views/TalentSearch/ProductAutoComplete';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  categoryProductsSelector,
  expertiseLevelsSelector,
  languagesSelector,
  modulesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';
import { getSearchTagsSelector } from 'store/selectors/getTagsSelector';
import {
  matchedTalentLoadingSelector,
  matchedTalentsSelector,
} from 'store/selectors/getTalentSearchSelector';
import searchConsultantTags from 'store/query/tags/searchConsultantTags';
import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import getLanguages from 'store/query/common/getLanguages';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';

import { convertToCamelCase } from 'utils/searchTalent';
import { StyledTalentFilterWrap } from '../styled';
import { tagsFilter } from 'helpers/tagsFilter';
import { ProductSelect } from 'components/ConsultantComponents/Products/Common';
import { activeSort, filterIsDeprecated } from 'helpers/decorators';
import useSafeAreaView from 'components/SafeAreaView';

interface ITalentSearchProps {
  onSubmit?: () => void;
  onAnonymousMode?: (isAnonymous: boolean) => void;
}

const DirectoryFilter: FunctionComponent<ITalentSearchProps> = ({ onSubmit, onAnonymousMode }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const { safeAreaPadding } = useSafeAreaView();

  const isMobileNative = Capacitor.isNativePlatform();

  const [tagsText, setTagsText] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const languages = useAppSelector(languagesSelector);
  const options = useAppSelector(getSearchTagsSelector(tagsText));
  const loadingResults = useAppSelector(matchedTalentLoadingSelector);
  const results = useAppSelector(matchedTalentsSelector);
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);
  const seniorities = useAppSelector(expertiseLevelsSelector);

  const {
    control,
    watch,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext();

  const currentProduct = watch('product');
  const primaryModule = watch('primaryModule');
  const primaryWorkType = watch('primaryWorkType');

  const modulesListByProduct = filterIsDeprecated(modulesList, currentProduct?.id);
  const worksListByProduct = filterIsDeprecated(worksList, currentProduct?.id);

  useEffect(() => {
    if (loadingResults && results.length === 0) {
      if (isiPadOrMobile) {
        setShowAdvancedFilters(false);
      }
    }
  }, [loadingResults, results, isiPadOrMobile]);

  useEffect(() => {
    if (!seniorities || seniorities.length === 0) {
      dispatch(fetchExpertiseLevels());
    }

    if (!languages || languages.length === 0) {
      dispatch(getLanguages());
    }

    if (!categoriesWithProductsList?.length || !modulesList?.length || !worksList.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
  }, [dispatch, categoriesWithProductsList, languages, modulesList, seniorities, worksList]);

  const debounced = debounce(value => {
    if (value) {
      dispatch(searchConsultantTags(value));
    }
    setTagsText(value);
  }, 500);

  const searchTerm = (value: string) => {
    debounced(value);
  };

  const handleSwitchChange = (event: any) => {
    const newValue = event.target.checked;
    onAnonymousMode?.(newValue);
  };

  const doSearch = () => onSubmit?.();

  const formValues = watch();

  const filter = Object.fromEntries(
    Object.entries({
      fullText: formValues.fullText || undefined,
      location: formValues.location,
      languages: formValues.languages || [],
      product: formValues.product?.name,
      works: formValues['expertises:modulesWorks:workTypeId'] || [],
      modules: formValues['expertises:modulesWorks:moduleId'] || [],
      seniorities: formValues['expertises:modulesWorks:levelId'] || [],
      tags: formValues.tags || [],
      typeId: formValues.typeId || undefined,
    }).filter(([_, value]) =>
      Array.isArray(value) ? value.length : value !== undefined && value !== null,
    ),
  );

  const onRemoveFilter = (key: string, value?: any) => {
    if (key && value) {
      if (key === 'works') {
        key = 'expertises:modulesWorks:workTypeId';
      }
      if (key === 'modules') {
        key = 'expertises:modulesWorks:moduleId';
      }
      if (key === 'seniorities') {
        key = 'expertises:modulesWorks:levelId';
      }
      const arrayName = getValues()?.[key];
      if (Array.isArray(arrayName)) {
        const newArray = arrayName.filter((obj: any) => obj.id !== value.id);
        setValue(key, newArray);
      } else {
        resetField(key);
      }
      if (key === 'languages') {
        setValue('languages:name', '');
      }
    } else {
      if (key === 'product') {
        resetField('expertises:productId');
        resetField('expertises:modulesWorks:workTypeId');
        resetField('expertises:modulesWorks:moduleId');
      }
      if (key === 'location') {
        resetField('locationObj');
      }
      if (key === 'seniorities') {
        resetField('expertises:modulesWorks:levelId');
      }
      setValue(key, '');
    }

    doSearch();
  };

  return (
    <StyledTalentFilterWrap>
      {/* Title */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>
          <Typography variant={mobile ? 'h3' : 'h1'} mt={!mobile ? 3 : 2}>
            Workerbee Directory
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography fontSize={'12px'} fontWeight={500} color={'#929292'}>
            Anonymous{' '}
          </Typography>
          <Switch
            onChange={handleSwitchChange}
            color="default"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: '#D4D4D4',
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#E7E2E2',
              },
            }}
          />
        </Box>
      </Box>

      {/* Full Text Search */}
      <Grid container spacing={2} sx={{ mt: 3, mb: 2 }}>
        <Grid xs={12} md={12} lg={9}>
          <InputLabel>Search</InputLabel>
          <Controller
            control={control}
            name="fullText"
            render={({ field: { ref, ...field } }) => (
              <ProductAutoComplete
                searchValue={field.value}
                setSearchValue={(newValue: any) => {
                  if (typeof newValue === 'string') {
                    setValue('fullText', newValue);
                    field.onChange(newValue);
                  } else if (newValue === null) {
                    setValue('fullText', '');
                    doSearch();
                  } else if (typeof newValue === 'object') {
                    setValue('product', newValue);
                    setValue('expertises:productId', newValue.id);
                    setValue('fullText', '');
                    resetField('expertises:modulesWorks:workTypeId');
                    resetField('expertises:modulesWorks:moduleId');
                    doSearch();
                  } else {
                    doSearch();
                  }
                }}
                onSearch={() => {
                  doSearch();

                  if (isMobileNative) {
                    Keyboard.hide();
                  }
                }}
                onClear={() => {
                  doSearch();
                }}
                placeholderText={`Search by name, email, product, ${mobile ? '' : 'types of work, '}modules, etc.`}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Grid>
        {!isiPadOrMobile && (
          <Grid xs={12} md={6} lg={3}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: '100%', marginTop: 4 }}
              onClick={onSubmit}
            >
              Search
            </Button>
          </Grid>
        )}
      </Grid>

      {/* Filter Tags */}
      <AvailableFilters
        filter={filter}
        onFilterRemove={(key: string, value?: any) => onRemoveFilter(key, value)}
      />

      {/* Mobile Filter Tags */}
      <Grid mt={1} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          sx={{ width: 'auto' }}
        >
          <Typography fontSize={'18px'} fontWeight={500} sx={{ borderBottom: '1px dotted #000' }}>
            {showAdvancedFilters ? 'Hide advanced filters' : 'Show advanced filters'}
          </Typography>
          {showAdvancedFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Grid>

      {/* Filters */}
      {/* First Row */}
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}
        display={!showAdvancedFilters ? 'none' : 'visible'}
      >
        <Grid xs={12} md={6} lg={3}>
          <InputLabel>Location</InputLabel>
          <Controller
            control={control}
            name="locationObj"
            render={({ field: { ref, value, ...field } }) => (
              <LocationInput
                {...field}
                inputRef={ref}
                value={value}
                onChange={(val: any) => {
                  setValue('location', val.label);
                  field.onChange(val);
                  doSearch();
                }}
                arrowIconSx={{ marginRight: '10px' }}
                error={!!errors.locationObj}
                errorText={'This field is required'}
                dataTest="consultant-location"
              />
            )}
          />
          {errors.locationObj && (
            <Typography color="error" variant="caption">
              {errors.locationObj.message}
            </Typography>
          )}
        </Grid>

        <Grid xs={12} md={6} lg={4.5}>
          <InputLabel>Language</InputLabel>
          <Controller
            control={control}
            name="languages"
            render={({ field: { ref, ...field } }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                handleChange={event => {
                  setValue(
                    'languages:name',
                    event.map((e: any) => e.name),
                  );
                  doSearch();
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                isMainTag
                options={convertToCamelCase(languages) || []}
                placeholder="Select Languages"
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4.5}>
          <InputLabel>Specialist Type</InputLabel>
          <Controller
            control={control}
            name="typeId"
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                inputRef={ref}
                label="All Specialists"
                options={[
                  { id: 'n', name: 'Independent Specialists Only' },
                  { id: 'a', name: 'Agency Specialists Only' },
                ]}
                labelMenuItem="name"
                arrowIconSx={{ right: '10px' }}
                isCuratedOrder={true}
                onChange={(event: any) => {
                  field.onChange(event.target.value);
                  doSearch();
                }}
              />
            )}
          />
        </Grid>

        {/* Second Row */}
        <Grid xs={12} md={6} lg={3}>
          <InputLabel>Product</InputLabel>
          <Controller
            control={control}
            name="expertises:productId"
            render={({ field }) => (
              <ProductSelect
                control={control}
                flatList={activeSort(categoriesWithProductsList)}
                name={'product'}
                handleChange={(product: any) => {
                  setValue('expertises:productId', product.id);
                  resetField('expertises:modulesWorks:workTypeId', { defaultValue: [] });
                  resetField('expertises:modulesWorks:moduleId', { defaultValue: [] });
                  doSearch();
                }}
                showAddProduct={false}
                selectedOptions={[]}
                hideLabel
                mobilesx={{ marginTop: safeAreaPadding.top }}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4.5}>
          <InputLabel>Types of work</InputLabel>
          <Controller
            control={control}
            name="expertises:modulesWorks:workTypeId"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.works,
                  helperText: (errors.works as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                isMainTag
                options={worksListByProduct || []}
                handleChange={event => {
                  setValue('expertises:modulesWorks:workTypeId', event);
                  doSearch();
                }}
                placeholder={!currentProduct ? 'No Product Selected' : 'Select a Type of Work'}
                primaryItem={primaryWorkType}
                onPrimaryItemChange={id => setValue('primaryWorkType', id)}
                disabled={!currentProduct}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4.5}>
          <InputLabel>Modules</InputLabel>
          <Controller
            control={control}
            name="expertises:modulesWorks:moduleId"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                handleChange={event => {
                  setValue('expertises:modulesWorks:moduleId', event);
                  doSearch();
                }}
                isMainTag
                options={modulesListByProduct || []}
                placeholder={!currentProduct ? 'No Product Selected' : 'Select a Module'}
                primaryItem={primaryModule}
                onPrimaryItemChange={id => setValue('primaryModule', id)}
                disabled={!currentProduct}
              />
            )}
          />
        </Grid>

        {/* Third Row */}
        <Grid xs={12} md={6} lg={3}>
          <InputLabel>Seniority Level</InputLabel>
          <Controller
            control={control}
            name="expertises:modulesWorks:levelId"
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                inputRef={ref}
                label="Select Level"
                options={seniorities || []}
                labelMenuItem="name"
                arrowIconSx={{ right: '10px' }}
                isCuratedOrder={true}
                onChange={(event: any) => {
                  field.onChange(event.target.value);
                  doSearch();
                }}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4.5}>
          <InputLabel>Tags</InputLabel>
          <Controller
            control={control}
            name="tags"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                  autoHighlight: true,
                  onInputChange: (event: SyntheticEvent, value: string) => {
                    searchTerm(value);
                    return true;
                  },
                }}
                inputProps={{
                  error: !!errors.tags,
                  helperText: (errors.tags as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': {
                      minWidth: '0px !important',
                      padding: 0,
                    },
                  },
                }}
                handleChange={event => {
                  field.onChange(event);
                  doSearch();
                }}
                isSortable
                field={field}
                options={options}
                placeholder="Start typing to add tags (e.g., 'JIRA', 'Training')..."
                customFilter={tagsFilter}
                freeSolo
              />
            )}
          />
        </Grid>

        <Grid
          xs={12}
          md={6}
          lg={4.5}
          sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'hidden' } }}
        >
          {' '}
          {/* Spacer */}
          <InputLabel>&nbsp;</InputLabel>
        </Grid>
      </Grid>
    </StyledTalentFilterWrap>
  );
};

export default DirectoryFilter;
