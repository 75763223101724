import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from 'styles/icons/EditIcon';
import { MapMarkIcon } from 'styles/icons/MapMarkIcon';

import { EConsultantEditingModals } from 'models/consts';
import {
  IBasicConsultant,
  IMatchedConsultant,
  IProductExperienceFull,
} from 'store/types/consultant';
import { IBasicUser } from 'store/types/user';

import { StyledIconBox, StyledName, StyledShortDescriptions } from './styled';
import { StyledOGIcon, StyledVerifiedIcon } from '../../DirectorySearchItem/styled';
import ConsultantExperiencePeriod from 'components/ConsultantComponents/ConsultantExperiencePeriod';
import { DirectorySearchResult } from 'store/types/directorySearch';
import HighlightedView from 'components/DirectorySearch/HighlightedView';

interface IDirectorySearchConsultantNameViewProps {
  consultant: DirectorySearchResult;
  isCurrentUser: boolean;
  isMobile?: boolean;
  expertiseSummary: IProductExperienceFull[];
  onOpen: (open: { type: number; open: boolean }) => void;
  onAnonymousMode?: boolean;
}

const DirectorySearchConsultantNameView: FunctionComponent<
  IDirectorySearchConsultantNameViewProps
> = ({ consultant, expertiseSummary, isCurrentUser, isMobile, onOpen, onAnonymousMode }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <StyledName data-test="consultant-name">
          <b>
            {' '}
            {onAnonymousMode ? (
              consultant?.initials
            ) : consultant?.firstName || consultant?.lastName ? (
              <>
                {consultant?.highlights?.includes('firstName') ? (
                  <HighlightedView text={consultant?.firstName || ''} />
                ) : (
                  consultant?.firstName
                )}{' '}
                {consultant?.highlights?.includes('lastName') ? (
                  <HighlightedView text={consultant?.lastName || ''} />
                ) : (
                  consultant?.lastName
                )}
              </>
            ) : (
              consultant?.initials
            )}
          </b>
        </StyledName>
        <StyledIconBox>
          {isCurrentUser && (
            <Box sx={{ flex: '1 1 100%', display: { xs: 'block', sm: 'none' } }}>
              <IconButton
                data-test="edit-profile-mobile"
                color="tertiary"
                size="small"
                onClick={() => onOpen({ open: true, type: EConsultantEditingModals.PROFILE })}
              >
                <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
              </IconButton>
            </Box>
          )}
        </StyledIconBox>
      </Box>
      <StyledShortDescriptions variant="subtitle2">
        {consultant?.highlights?.includes('description') ? (
          <HighlightedView text={consultant?.shortDescriptions || ''} />
        ) : (
          consultant?.shortDescriptions
        )}
      </StyledShortDescriptions>
      {!isMobile && (
        <Box display="flex" mb={2}>
          {consultant?.isApproved && (
            <Box data-test="approved-profile">
              <StyledVerifiedIcon />
            </Box>
          )}
          <Box>
            <StyledOGIcon />
          </Box>
        </Box>
      )}
      <ConsultantExperiencePeriod expertiseSummary={expertiseSummary} />
      {consultant?.location && (
        <Box display="flex" alignItems="stretch">
          <Box sx={{ ml: 0.375, width: 22, mr: 1 }}>
            <MapMarkIcon />
          </Box>
          <Typography
            component="div"
            sx={{ alignItems: 'center', lineHeight: 'normal', display: 'flex', flex: '1 0 90%' }}
          >
            {consultant?.highlights?.includes('location') ? (
              <HighlightedView text={consultant?.location || ''} />
            ) : (
              consultant?.location
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default DirectorySearchConsultantNameView;
