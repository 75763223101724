import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EUser } from 'store/constants';
import { ICustomerCompanyDto } from '../../types/company';

interface IUpdateCustomerCompanyProps {
  company: ICustomerCompanyDto;
  userId: number;
}

const updateCustomerCompany = createAsyncThunk<any, IUpdateCustomerCompanyProps>(
  EUser.updateCustomerCompany,
  async ({ company }) => {
    const { data } = await instance.patch(`customer/update-company`, company);

    return data;
  },
);

export default updateCustomerCompany;
