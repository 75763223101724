import React, { useState, FunctionComponent, useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ICuratedOrderToggleSwitchProps {
  selectedToggleValue: (value: 'default' | 'custom' | null) => void;
  disabled?: boolean;
  defaultValue: 'default' | 'custom';
}

const CuratedOrderToggleSwitch: FunctionComponent<ICuratedOrderToggleSwitchProps> = ({
  selectedToggleValue,
  disabled,
  defaultValue,
}) => {
  const [selected, setSelected] = useState<'default' | 'custom'>(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: 'default' | 'custom' | null,
  ) => {
    if (newSelection !== null) {
      setSelected(newSelection);
      selectedToggleValue(newSelection);
    }
  };

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleToggle}
      disabled={disabled}
      aria-label="switch"
      sx={{
        // width: '167px',
        height: '28px',
        borderRadius: '30px',
        overflow: 'hidden',
        backgroundColor: '#e0e0e0',
      }}
    >
      <ToggleButton
        value="default"
        aria-label="default"
        sx={{
          textTransform: 'none',
          fontSize: '12px',
          color: selected === 'default' ? '#000' : '#757575',
          border: selected === 'default' ? '1px solid #000' : 'none',
          '&.Mui-selected': {
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #000',
            borderRadius: '30px',
          },
          '&:hover': {
            backgroundColor: selected === 'default' ? '#f5f5f5' : 'transparent',
          },
        }}
      >
        Default
      </ToggleButton>
      <ToggleButton
        value="custom"
        aria-label="custom"
        sx={{
          textTransform: 'none',
          fontSize: '12px',
          color: selected === 'custom' ? '#000' : '#757575',
          border: selected === 'custom' ? '1px solid #000' : 'none',
          '&.Mui-selected': {
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #000',
            borderRadius: '30px',
          },
          '&:hover': {
            backgroundColor: selected === 'custom' ? '#f5f5f5' : 'transparent',
          },
        }}
      >
        Custom
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CuratedOrderToggleSwitch;
