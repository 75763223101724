import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autocomplete, Box, InputAdornment, Typography } from '@mui/material';

import { BootstrapInput, RadioGroupInput } from 'components/CustomFields';
import DateTextInput from 'components/CustomFields/DateTextInput';
import CustomTooltip from 'components/CustomTooltip';
import CuratedOrderToggleSwitch from '../CuratedOrderToggleSwitch';

import { useAppDispatch } from 'store/hooks';
import { EMAIL_REGEX, LIMITS } from 'models/consts';
import getCustomerList from 'store/query/user/getCustomerList';
import getConsultantList from 'store/query/user/getConsultantList';
import fetchUsersList from 'store/query/user/fetchUsersList';

import { getEndDate, isFriday, isMonday } from 'utils/dateFormatter';
import { handlerTimeLineChange } from 'utils/orderCreationHelpers';
import CalendarIcon from 'styles/icons/CalendarIcon';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { customFilter } from 'helpers/customFilter';
import checkCompanyByName from 'store/query/company/checkCompanyByName';

interface isCuratedOrderDetailsProps {
  disableAllFields?: boolean;
  isOrderEdit: any;
}

const CuratedOrderDetails: FunctionComponent<isCuratedOrderDetailsProps> = ({
  disableAllFields,
  isOrderEdit,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const [customerInfoType, setCustomerInfoType] = useState<string>();
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [salesManagerData, setSalesManagerData] = useState<any[]>([]);
  const [specialistData, setSpecialistData] = useState<any[]>([]);

  const {
    control,
    clearErrors,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    salesManager,
    customer,
    specialist,
    orderName,
    description,
    customerRate,
    isCustomerRateDefault,
    specialistRate,
    isSpecialistRateDefault,
    totalHours,
    searchEndDate,
    searchStartDate,
    customerType,
    customerEmail,
  } = watch();

  const [isSpecialistDefaultRate, setIsSpecialistDefaultRate] = useState<
    'default' | 'custom' | null
  >(isSpecialistRateDefault ? 'default' : 'custom');
  const [isCustomerDefaultRate, setIsCustomerDefaultRate] = useState<'default' | 'custom' | null>(
    isCustomerRateDefault ? 'default' : 'custom',
  );

  useEffect(() => {
    setIsCustomerDefaultRate(isCustomerRateDefault ? 'default' : 'custom');
    setIsSpecialistDefaultRate(isSpecialistRateDefault ? 'default' : 'custom');
  }, [isCustomerRateDefault, isSpecialistRateDefault]);

  useEffect(() => {
    if (customerType) {
      setCustomerInfoType(customerType);
    }
  }, [customerType]);

  useEffect(() => {
    dispatch(getConsultantList({ withAgency: 'false', isActive: true })).then((data: any) => {
      setSpecialistData(data?.payload?.consultants || []);
    });
    dispatch(getCustomerList({ offset: 0, type: 'asc' })).then((data: any) => {
      setCustomerData(data?.payload?.customers || []);
    });
    dispatch(fetchUsersList({})).then((data: any) => {
      setSalesManagerData(data?.payload?.users || []);
    });
  }, [dispatch]);

  useEffect(() => {
    if (salesManagerData && salesManager) {
      const filterSalesManager = salesManagerData.find(
        (userData: { id: any }) => userData.id === salesManager,
      );
      if (filterSalesManager) {
        setValue('salesManager', filterSalesManager);
      } else {
        console.warn('No matching salesManager found in salesManagerData.');
      }
    }
  }, [salesManager, salesManagerData, setValue]);

  const debounced = debounce((value: any, searchField: string) => {
    if (searchField === 'customer') {
      dispatch(
        getCustomerList({
          offset: 0,
          type: 'asc',
          search: value,
        }),
      ).then((data: any) => {
        setCustomerData(data?.payload?.customers || []);
      });
    }
    if (searchField === 'specialist') {
      dispatch(getConsultantList({ withAgency: 'false', isActive: true, search: value })).then(
        (data: any) => {
          setSpecialistData(data?.payload?.consultants || []);
        },
      );
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  const setDate = (value: Date | null, fieldName: any, field: any) => {
    field?.onChange(value ? dayjs(value || null).format('YYYY-MM-DD') : undefined);
  };

  const onSwitchToggle = (value: any, type: 'customer' | 'specialist') => {
    if (type === 'customer') {
      setIsCustomerDefaultRate(value);
      setValue('isCustomerRateDefault', value === 'default' ? true : false);
    } else {
      setIsSpecialistDefaultRate(value);
      setValue('isSpecialistRateDefault', value === 'default' ? true : false);
    }
  };

  const checkIsCompanyNotExist = (companyName: string | undefined) => {
    if (companyName) {
      return dispatch(checkCompanyByName({ name: companyName, availableForCustomer: true }))
        .unwrap()
        .then(({ isHave }) => {
          if (isHave) {
            setValue('companyName', undefined);
          }
          return !isHave;
        });
    }
    return true;
  };

  return (
    <Grid sx={{ ml: 1, mt: 1, mb: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid
        sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: isiPadOrMobile ? 'column' : 'row' }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            width: isiPadOrMobile ? '100%' : '60%',
            mr: 5,
          }}
        >
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: isiPadOrMobile ? '25px' : '30px',
              fontWeight: 500,
            }}
          >
            Order Details
          </Typography>

          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 120,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Sales Manager
            </InputLabel>
            <Controller
              control={control}
              name="salesManager"
              render={({ field: { ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  disabled={disableAllFields}
                  sx={{
                    width: isiPadOrMobile ? '100%' : '58%',
                  }}
                  onChange={(_, newValue) => {
                    setValue('salesManager', newValue);
                    field.onChange(newValue);
                  }}
                  options={salesManagerData}
                  getOptionLabel={option => {
                    return option && option.firstName
                      ? `${option.firstName} ${option.lastName}`
                      : option?.email
                        ? option.email
                        : '';
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={params => (
                    <BootstrapInput {...params} placeholder="Select an option" />
                  )}
                />
              )}
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 140,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Order Name{' '}
              <Typography component="span" color="red">
                *
              </Typography>
            </InputLabel>
            <Controller
              control={control}
              name="orderName"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="text"
                  placeholder="Type your order name here..."
                  disabled={disableAllFields}
                  sx={{
                    width: isiPadOrMobile ? '100%' : '70%',
                    marginLeft: isiPadOrMobile ? undefined : 0.7,
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(value);
                    setValue('orderName', value);
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                required: 'This field is required',
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={6}
            display={'flex'}
            flexDirection={isiPadOrMobile ? 'column' : 'row'}
          >
            <InputLabel
              sx={{
                width: 140,
                marginTop: isiPadOrMobile ? 0 : 1,
                fontSize: '14px',
                fontWeight: 400,
                color: '#5D5D5E',
              }}
            >
              Description
            </InputLabel>
            <Controller
              control={control}
              name="description"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="text"
                  multiline={true}
                  rows={isiPadOrMobile ? 1.5 : 3}
                  disabled={disableAllFields}
                  placeholder="Type your order description here..."
                  sx={{
                    width: '100%',
                    marginLeft: 0.5,
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(value);
                    setValue('description', value);
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                required: 'This field is required',
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{
            mt: 1,
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: isiPadOrMobile ? '100%' : '50%',
          }}
        >
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: isiPadOrMobile ? '25px' : '30px',
              fontWeight: 500,
            }}
          >
            Customer Info
          </Typography>

          <Grid xs={12} md={12} lg={6} mb={isiPadOrMobile ? -2 : -4}>
            <Controller
              control={control}
              name="customerType"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <RadioGroupInput
                  {...field}
                  ariaLabelledby="customer-type"
                  values={['existing_customer', 'new_customer']}
                  disabled={disableAllFields}
                  labels={{
                    ['existing_customer']: {
                      subLabel1: 'Existing Customer',
                      subLabel2: `Order for my existing customer`,
                    },
                    ['new_customer']: {
                      subLabel1: 'New Customer',
                      subLabel2: `I have a lead with new customer`,
                    },
                  }}
                  defaultValue={customerType}
                  onChange={event => {
                    field.onChange(event);
                    const selectedType = event.target.value;
                    setCustomerInfoType(selectedType);
                    setValue('customerType', selectedType);
                    if (customerType === 'existing_customer') {
                      setValue('companyName', undefined);
                      setValue('customerEmail', undefined);
                    }
                  }}
                />
              )}
            />
          </Grid>
          {customerType === 'new_customer' && (
            <>
              <Grid xs={12} md={12} lg={6}>
                <InputLabel
                  sx={{
                    width: 120,
                    marginTop: isiPadOrMobile ? 0 : 1,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Customer Email
                </InputLabel>
                <Controller
                  control={control}
                  name="customerEmail"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      inputRef={ref}
                      error={!!errors.email}
                      placeholder="Enter Customer email"
                      helperText={errors.email?.message || 'Incorrect data'}
                      type="email"
                      onChange={(event: any) => {
                        setValue('customerEmail', event.target.value);
                      }}
                      disabled={!customerInfoType || disableAllFields}
                      sx={{ width: '100%' }}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email',
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <InputLabel
                  sx={{
                    width: 120,
                    marginTop: isiPadOrMobile ? 0 : 1,
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  Company Name
                </InputLabel>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      inputRef={ref}
                      placeholder="Type a company name"
                      disabled={disableAllFields}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        field.onChange(value);
                        setValue('companyName', value);
                      }}
                      error={!!errors.companyName}
                      helperText={errors.companyName?.message || 'Incorrect data'}
                    />
                  )}
                  rules={{
                    maxLength: {
                      message: 'The max count of characters is 100',
                      value: 100,
                    },
                    validate: {
                      checkCompanyExist: async (companyName?: string) =>
                        (await checkIsCompanyNotExist(companyName)) ||
                        'This Company name already exists',
                    },
                  }}
                />
              </Grid>
            </>
          )}
          {customerType === 'existing_customer' && (
            <Grid xs={12} md={12} lg={6}>
              <InputLabel
                sx={{
                  width: 120,
                  marginTop: isiPadOrMobile ? 0 : 1,
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                Customer
              </InputLabel>
              <Controller
                control={control}
                name="customer"
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    disabled={disableAllFields}
                    onInputChange={(_, value) => {
                      searchTerm(value, 'customer');
                    }}
                    onChange={(_, newValue) => {
                      setValue('customer', newValue);
                      field.onChange(newValue);
                    }}
                    options={customerData}
                    getOptionLabel={option => {
                      return option
                        ? `${option.firstName || ''} ${option.lastName || option.email} ${
                            option?.customer?.companies[0]?.name
                              ? ` - ${option.customer.companies[0].name}`
                              : ''
                          }`
                        : '';
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={params => (
                      <BootstrapInput {...params} placeholder="Select an option" />
                    )}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} display={'flex'} flexDirection={'column'}>
        <Grid
          xs={12}
          md={6}
          lg={4}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Start date
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: isiPadOrMobile ? 'auto' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              name="searchStartDate"
              control={control}
              rules={{
                validate: {
                  beforeEnd: date => {
                    if (searchEndDate) {
                      const startDate = new Date(date);
                      const endDate = new Date(`${searchEndDate}T00:00:00`);
                      if (startDate > endDate) {
                        return 'Start Date cannot be in future than End Date';
                      }
                    }
                    return undefined;
                  },
                },
              }}
              render={({ field }) => (
                <DatePicker
                  selected={!!searchStartDate ? dayjs(searchStartDate).toDate() : null}
                  disabled={disableAllFields}
                  onChange={(date: Date) => {
                    if (date) {
                      handlerTimeLineChange(
                        totalHours,
                        40,
                        dayjs(date).format('YYYY-MM-DD'),
                        setValue,
                        clearErrors,
                      );
                    }
                    setDate(date, 'startDate', field);
                  }}
                  calendarStartDay={1}
                  customInput={
                    <DateTextInput
                      className="text-custom-input"
                      onReset={() => setDate(null, 'startDate', field)}
                      icon={<CalendarIcon />}
                      data-test="startDateField"
                    />
                  }
                  isClearable
                  showPopperArrow={false}
                  filterDate={isMonday}
                  minDate={dayjs().add(1, 'days').toDate()}
                  placeholderText="MM/DD/YYYY"
                  dayClassName={date => (isMonday(date) && 'available-start-date') || null}
                  popperPlacement="bottom-start"
                />
              )}
            />
            {!!errors.searchStartDate && (
              <Typography color="error">
                {(errors.searchStartDate as any)?.message || 'Incorrect data'}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            End date
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? 'auto' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              name="searchEndDate"
              control={control}
              rules={{
                validate: {
                  afterStart: date =>
                    !date ||
                    !searchStartDate ||
                    dayjs(searchStartDate).isSameOrBefore(date) ||
                    'Incorrect date range',
                },
              }}
              render={({ field }) => (
                <DatePicker
                  selected={!!searchEndDate ? dayjs(searchEndDate).toDate() : null}
                  disabled={disableAllFields}
                  onChange={(date: Date) => {
                    setDate(date, 'searchStartDate', field);
                    if (searchStartDate && dayjs(searchStartDate).isSameOrBefore(date)) {
                      clearErrors('searchStartDate');
                    }
                  }}
                  calendarStartDay={1}
                  customInput={
                    <DateTextInput
                      className="text-custom-input"
                      onReset={() => setDate(null, 'searchStartDate', field)}
                      icon={<CalendarIcon />}
                      data-test="endDateField"
                    />
                  }
                  showPopperArrow={false}
                  minDate={searchStartDate ? dayjs(searchStartDate).toDate() : dayjs().toDate()}
                  filterDate={isFriday}
                  isClearable
                  placeholderText="MM/DD/YYYY"
                  dayClassName={date => (isFriday(date) && 'available-end-date') || null}
                  popperPlacement="bottom-start"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Customer Rate
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? '97%' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              control={control}
              name="customerRate"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  disabled={isCustomerDefaultRate === 'default' || disableAllFields}
                  placeholder="Enter Customer Rate"
                  helperText={errors.customerRate?.message || 'Incorrect data'}
                  error={!!errors.customerRate}
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography
                        ml={2}
                        color="secondary"
                        sx={{
                          display: 'flex',
                          marginRight: '-20px',
                          marginTop: 0.2,
                          fontSize: '14px',
                          color: isCustomerDefaultRate === 'default' ? '#757575' : '#000',
                        }}
                      >
                        {customerRate ? '$' : undefined}
                      </Typography>
                    </InputAdornment>
                  }
                  sx={{
                    width: '100%',
                    backgroundColor: '#f5f5f5',
                  }}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(parseInt(value));
                    setValue('customerRate', parseInt(value));
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                validate: {
                  greaterThanZero: value => {
                    if (Number(value) <= 0) {
                      return 'Rate must be greater than 0';
                    }
                  },
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={isiPadOrMobile ? 1 : undefined}
          >
            <CuratedOrderToggleSwitch
              selectedToggleValue={value => onSwitchToggle(value, 'customer')}
              defaultValue={isCustomerRateDefault ? 'default' : 'custom'}
              disabled={disableAllFields}
            />
            <CustomTooltip
              placement="top"
              title="Default Rate is determined by Product and Seniority"
              sx={{ width: '10px', height: '10px', ml: 1 }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Specialist Rate
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isiPadOrMobile ? 'column' : 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? '97%' : '31%',
              marginRight: 1,
            }}
          >
            <Controller
              control={control}
              name="specialistRate"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  disabled={isSpecialistDefaultRate === 'default' || disableAllFields}
                  placeholder="Enter Specialist Rate"
                  helperText={errors.specialistRate?.message || 'Incorrect data'}
                  error={!!errors.specialistRate}
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography
                        ml={2}
                        color={'secondary'}
                        sx={{
                          display: 'flex',
                          marginRight: '-20px',
                          marginTop: 0.2,
                          color: isSpecialistDefaultRate === 'default' ? '#757575' : '#000',
                        }}
                      >
                        {specialistRate ? '$' : undefined}
                      </Typography>
                    </InputAdornment>
                  }
                  onChange={(event: any) => {
                    const value = event.target.value;
                    field.onChange(parseInt(value));
                    setValue('specialistRate', parseInt(value));
                  }}
                  autoComplete="off"
                />
              )}
              rules={{
                validate: {
                  greaterThanZero: value => {
                    if (Number(value) <= 0) {
                      return 'Rate must be greater than 0';
                    }
                  },
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={isiPadOrMobile ? 1 : undefined}
          >
            <CuratedOrderToggleSwitch
              selectedToggleValue={value => onSwitchToggle(value, 'specialist')}
              defaultValue={isSpecialistRateDefault ? 'default' : 'custom'}
              disabled={disableAllFields}
            />
            <CustomTooltip
              placement="top"
              title="Default Rate is determined by Product and Seniority"
              sx={{ width: '10px', height: '10px', ml: 1 }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display="flex"
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={theme => ({
              width: isiPadOrMobile ? '100%' : 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
              whiteSpace: isiPadOrMobile ? 'nowrap' : 'normal',
              wordBreak: isiPadOrMobile ? 'normal' : 'break-word',
              overflow: isiPadOrMobile ? 'hidden' : 'visible',
              textOverflow: isiPadOrMobile ? 'ellipsis' : 'unset',
              lineHeight: 1.4,
              [theme.breakpoints.up('lg')]: {
                maxWidth: '100%',
              },
            })}
          >
            Specialist Net Payout Terms
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: isiPadOrMobile ? 'auto' : '13%',
            }}
          >
            <Controller
              control={control}
              name="payoutTerms"
              rules={{
                validate: value => value >= 0 || 'Value must be 0 or greater',
              }}
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  type="number"
                  sx={{ width: '100%' }}
                  onChange={(event: any) => {
                    const value = Math.max(0, parseInt(event.target.value));
                    field.onChange(value);
                    setValue('payoutTerms', value);
                  }}
                  autoComplete="off"
                  disabled={disableAllFields}
                />
              )}
            />
            <Box display="flex" flexDirection="row" alignItems="center" ml={1}>
              <Typography>days</Typography>
              <CustomTooltip
                placement="top"
                title="Days from weekly billing approval"
                sx={{ width: '10px', height: '10px', ml: 1 }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          display={'flex'}
          flexDirection={isiPadOrMobile ? 'column' : 'row'}
        >
          <InputLabel
            sx={{
              width: 120,
              marginTop: isiPadOrMobile ? 0 : 1,
              fontSize: '14px',
              fontWeight: 400,
              color: '#5D5D5E',
            }}
          >
            Specialist
          </InputLabel>
          <Controller
            control={control}
            name="specialist"
            render={({ field: { ref, ...field } }) => (
              <Autocomplete
                {...field}
                disabled={disableAllFields}
                onInputChange={(_, value) => {
                  searchTerm(value, 'specialist');
                }}
                onChange={(_, newValue) => {
                  setValue('specialist', newValue);
                  field.onChange(newValue);
                }}
                options={specialistData}
                getOptionLabel={option => {
                  return option
                    ? `${option.firstName || ''} ${option.lastName || option.email}`
                    : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <BootstrapInput {...params} placeholder="Select an option" />
                )}
                sx={{ width: isiPadOrMobile ? '97%' : '31%' }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CuratedOrderDetails;
