import mapValues from 'lodash/fp/mapValues';
import isObject from 'lodash/isObject';
import flatMap from 'lodash/fp/flatMap';
import flowRight from 'lodash/fp/flowRight';
import { filter, map } from 'lodash/fp';

import {
  ICategoryWithProducts,
  IExtendCategoryWithProducts,
  IProduct,
} from '../store/types/categoryProducts';
import { flow, orderBy } from 'lodash/fp';

export const outputQuery = mapValues((value: any) => {
  // @ts-ignore
  if (isObject(value) && value?.id !== undefined) {
    // @ts-ignore
    return value?.id;
  }
  return value;
});

export const flatProductsList = flowRight(
  flatMap((category: any) =>
    category.products.map((product: IProduct) => ({
      categoryName: category.name,
      categoryId: category.id,
      ...product,
      isBasic: category.isBasic,
    })),
  ),
  map((o: ICategoryWithProducts) => ({
    ...o,
    products: [...o.products].sort((a, b) => {
      // isActive comparison - true values sorted before false
      const activeCompare = b.isActive === a.isActive ? 0 : a.isActive ? -1 : 1;
      if (activeCompare !== 0) return activeCompare;

      // Finally, sort by the item's name
      return a.name.localeCompare(b.name);
    }),
  })),
);

export const filterIsDeprecated = (list: any[], id: number) =>
  list.filter(({ productId, isDeprecated }) => productId === id && !isDeprecated);

export const toActive = map((obj: any) => ({ ...obj, isActive: true }));

export const activeSort: (
  categories: IExtendCategoryWithProducts[],
  includeDeprecated?: boolean,
) => IExtendCategoryWithProducts[] = (categories, includeDeprecated = false) =>
  flow(
    // Conditionally filter out deprecated products if includeDeprecated is false
    filter((obj: IExtendCategoryWithProducts) => (includeDeprecated ? true : !obj.isDeprecated)),
    map((obj: IExtendCategoryWithProducts) => ({ ...obj, isActive: true })),
    orderBy(['categoryId', 'isActive', 'name'], ['asc', 'desc', 'asc']),

    (sortedCategories): IExtendCategoryWithProducts[] =>
      sortedCategories as IExtendCategoryWithProducts[],
  )(categories);
