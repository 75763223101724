import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

import { IExpertiseBasis } from 'models/inner-models';

export interface IParams {
  companyId: number;
  customerId: number;
  description: string;
  expertise: IExpertiseBasis;
  workerId?: number;
  agencyId?: number;
  levelId: number;
  name: string;
  timeLine: any;
  purchaseOrderId?: number;
  workerMatching: number;
  customPrice?: any;
  isQuick?: boolean;
}
const createOrder = createAsyncThunk<any, IParams>(
  EOrder.create,
  async ({
    companyId,
    customerId,
    description,
    expertise,
    workerId,
    agencyId,
    levelId,
    name,
    timeLine,
    workerMatching,
    purchaseOrderId,
    customPrice,
    isQuick = false,
  }) => {
    let api;

    const expertiseId = await instance.post('expertises', [
      {
        productId: (expertise as Required<IExpertiseBasis>)?.product?.id || -1,
        modules: (expertise as Required<IExpertiseBasis>).modules.map(({ id }) => ({
          moduleId: id,
          isPrimary: expertise.primaryModule === id,
        })),
        works: (expertise as Required<IExpertiseBasis>).works.map(({ id }) => ({
          workId: id,
          isPrimary: expertise.primaryWorkType === id,
        })),
      },
    ]);

    const timeLineId = await instance.post('time-lines', [
      {
        allHours: +timeLine.actualizedTime || +timeLine.allHours,
        startDate: timeLine.startDate,
        endDate: timeLine.endDate,
        availabilityWeek: timeLine.availabilityWeek,
        flexibleHours: timeLine.flexibleHours,
      },
    ]);

    const dataOrder: any = {
      description: description,
      expertiseId: expertiseId.data[0].id,
      timeLineId: timeLineId.data[0].id,
      levelId: levelId,
      companyId: companyId,
      customerId: customerId,
      name: name,
      workerMatching: workerMatching,
      ...(customPrice && {
        customPrise: customPrice,
      }),
      isQuick,
    };
    if (workerId) {
      dataOrder.workerId = workerId;
    } else if (agencyId) {
      dataOrder.agencyId = agencyId;
    }

    if (purchaseOrderId) {
      dataOrder.purchaseOrderId = purchaseOrderId;
      api = 'enterprise/orders';
    } else {
      api = 'orders';
    }

    const { data } = await instance.post(api, [dataOrder]);

    return data;
  },
);

export default createOrder;
