import { createAsyncThunk } from '@reduxjs/toolkit';
import { EUser } from 'store/constants';

import instance from 'store/middlewares/api';

interface IFetchUsersList {
  count: number;
  page: number;
  search: string;
}

const fetchUsersList = createAsyncThunk<IFetchUsersList, any>(
  EUser.fetchUsersList,
  async ({ payload }) => {
    const { data } = await instance.get(`/admin/users`, payload);

    return data;
  },
);

export default fetchUsersList;
