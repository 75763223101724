import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { VerifiedIcon } from 'styles/icons/VerifiedIcon';
import { LinkedinIcon } from 'styles/icons/LinkedinIcon';
import { OGIcon } from 'styles/icons/OGIcon';

const StyledPaper = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: 2,
    boxShadow: '0 4px 10px rgb(0 0 0 / 20%)',
    border: '1px solid #EBEBEB',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    mx: {
      xs: -1,
      md: 0,
    },
  }),
);

const StyledCardActionArea = styled(CardActionArea)(({ theme }) =>
  theme.unstable_sx({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    p: 2.5,
    flexShrink: 2,
  }),
);

const StyledClamp = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const StyledMoreTags = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    display: 'inline-flex',
    mb: 0.25,
    ml: 0.5,
    verticalAlign: 'middle',
    alignItems: 'center',
  }),
);
const StyledMatchingValue = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '1.875rem',
      md: '3rem',
    },
    lineHeight: 'normal',
    fontWeight: 300,
    height: {
      md: 50,
    },
    mb: {
      md: 1,
    },
    width: {
      xs: 106,
      md: 'auto',
    },
  }),
);

const StyledMatch = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '.875rem',
      md: '1rem',
    },
    ml: {
      xs: 2,
      md: 0,
    },
  }),
);

const StyledCostPerWeek = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: '1.125rem',
      md: '1rem',
    },
    textAlign: 'center',
  }),
);

const StyledAvatarWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mr: 2,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: {
      md: 100,
      lg: 144,
    },
    flex: {
      md: '1 0 100px',
      lg: '1 0 144px',
    },
  }),
);

const StyledSocialWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mb: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 82,
  }),
);

const StyledCompanyLogo = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    mt: 2.5,
    height: 35,
    flexWrap: 'wrap',
  }),
);

const StyledTagsWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    overflow: 'hidden',
    height: 33,
    flexWrap: 'wrap',
    mt: 1.5,
  }),
);

const StyledStarChip = styled(Chip)(({ theme }) =>
  theme.unstable_sx({
    fontSize: '.750rem',
    fontWeight: 700,
    display: 'Flex',
  }),
);

const StyledOGIcon = styled(OGIcon)(({ theme }) =>
  theme.unstable_sx({
    ml: 0.5,
    mr: 0.5,
  }),
);

const StyledVerifiedIcon = styled(VerifiedIcon)(({ theme }) =>
  theme.unstable_sx({
    mr: 0.5,
  }),
);

const StyledLinkedinIcon = styled(LinkedinIcon)(({ theme }) =>
  theme.unstable_sx({
    ml: 0.5,
  }),
);

const StyledCompanyIconsTags = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    ml: 2,
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
  }),
);

const StyledAgencyContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: '#F5F5F5',
    borderRadius: '100px',
    paddingX: 2,
    paddingY: 1,
  }),
);

const StyledAgencyName = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: '500',
});

export {
  StyledAvatarWrap,
  StyledCardActionArea,
  StyledClamp,
  StyledCostPerWeek,
  StyledCompanyIconsTags,
  StyledCompanyLogo,
  StyledLinkedinIcon,
  StyledMatch,
  StyledMatchingValue,
  StyledMoreTags,
  StyledOGIcon,
  StyledPaper,
  StyledSocialWrap,
  StyledStarChip,
  StyledTagsWrap,
  StyledVerifiedIcon,
  StyledAgencyContainer,
  StyledAgencyName,
};
