import React, { FunctionComponent } from 'react';
import dayjs from 'utils/dayjs';

import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { IOrdersHistory } from 'store/types/order';
import { getOrderActionText } from 'utils/orderCreationHelpers';

interface IOrderHistoryProps {
  isPreOrder?: boolean;
  history: IOrdersHistory[];
}

const OrderHistory: FunctionComponent<IOrderHistoryProps> = ({ isPreOrder, history }) => (
  <Box mb={3}>
    {isPreOrder && (
      <Typography variant="h4" mb={1}>
        Preorder History
      </Typography>
    )}
    {!isPreOrder && (
      <Typography variant="h4" mb={1}>
        Order History
      </Typography>
    )}
    <TableContainer>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 0 }}>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>By</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row: IOrdersHistory) => (
            <TableRow key={row.id} sx={{ '& td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ pl: 0 }}>{dayjs.utc(row.createdAt).format('MM/DD/YYYY')}</TableCell>
              <TableCell>{dayjs.utc(row.createdAt).format('HH:mm:ss')}</TableCell>
              <TableCell>{getOrderActionText(row.changesType)}</TableCell>
              <TableCell>
                {isPreOrder
                  ? row.metadata.createdBy
                  : (!row.user?.firstName && !row.user?.lastName && 'System') ||
                    `${row.user?.firstName} ${row.user?.lastName}`}
              </TableCell>
              <TableCell>{row.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default OrderHistory;
