enum ROUTES {
  CALENDAR = '/calendar',
  HOME = '/home',
  CONSULTANT_REGISTRATION = '/consultant-register',
  CONSULTANT = '/bee',
  SHARABLE_CONSULTANT = '/sharable-specialist',
  ORDER_CREATION = '/order-flow',
  STEP = ':step',
  ORDER_LIST = '/',
  ORDER = '/order',
  PURCHASE_ORDER_LIST = '/payment-authorization-list',
  AGENCY_CUSTOMER_ORDER = '/customer-orders',
  AGENCY_PREORDER = '/agency-preorders',
  CONTACT_DETAILS = '/contact-details',
  SECURITY = '/security',
  CHAT = '/chat',
  CHAT_ROOM = ':room',
  CHAT_ASSISTANT = '/chat-assistant',
  SPECIALIST = 'specialist',
  TEAM = '/team',
  PARTNER_RATES = '/partner-rates',
  PURCHASE_ORDERS = '/payment-authorizations',
  TERMS_OF_USE = '/terms-of-use',
  PRIVACY_POLICY = '/privacy-policy',
  CUSTOMER_USER_AGREEMENT = '/customer-user-agreement',
  PAYMENT_DETAILS = '/payment_details',
  LOGIN_JWT = '/login-jwt',
  PAYMENT = '/payment',
  CONSULTANT_ORDER_CREATION = '/consultant-order-creation',
  AGENCY_CREATION = '/agency-creation',
  EDIT_PREORDER = '/preorder/:id/edit',
  AGENCY_PROFILE = '/agency-profile',
  IMPERSONATION = '/impersonation',
  INFLUENCER_HOME = '/influencer',
  EMAIL_UNSUBSCRIBE = '/unsubscribe/:uuid',
  CONSULTANT_VALIDATE_AVAILABILITY = '/validate-availability/:hours/:uuid',
  TALENT_SEARCH_LANDING = '/talent-search-landing',
  TALENT_SEARCH_RESULTS = '/talent-search-results',
  DIRECTORY_SEARCH_RESULTS = '/directory-search-results',
  CURATED_ORDERS_LANDING = '/curated-orders-landing',
  SALES_CREATE_ORDER = '/sales-create-order',
  TEAMS = '/teams',

  // Refactor
  AUTH = '/auth',
  CODE = '/auth/verification-code',
  CUSTOMER_REGISTRATION = '/auth/customer-register',
  DRAFT_ORDER = '/draft-order',
  FORGOT_PASSWORD = '/auth/forgot-password',
  PRE_ORDER = '/preorder',
  PRODUCT_AND_EXPERIENCE = '/product-and-experience',
  RESET_PASSWORD = '/auth/reset-password',
  SIGN_UP = '/auth/signup',
  SIGN_UP_CUSTOMER = '/auth/signup-customer',
  SIGN_UP_ENTERPRISE_CUSTOMER = '/auth/signup-enterprise-customer',
  SIGN_UP_CUSTOMER_FOR_DRAFT_ORDER = '/auth/signup-draft-customer',
  SIGN_UP_AGENCY = '/auth/signup-agency',
  SIGNUP_CODE = '/auth/signup-verification-code',

  ORDERS_BY_TYPE = '/orders/:type',
}

export default ROUTES;
