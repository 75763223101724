import { createSelector } from 'reselect';
import { DefaultRootState } from 'react-redux';

const getDirectorySearchState = (state: DefaultRootState) => state.directorySearch;

export const getDirectorySearchItemsSelector = createSelector(
  [getDirectorySearchState],
  directorySearchState => directorySearchState.directorySearch.items,
);

export const getDirectorySearchLoadingSelector = createSelector(
  [getDirectorySearchState],
  directorySearchState => directorySearchState.directorySearch.loading,
);

export const getDirectorySearchCountSelector = createSelector(
  [getDirectorySearchState],
  directorySearchState => directorySearchState.directorySearch.countAll,
);

export const getDirectorySearchHasMoreSelector = createSelector(
  [getDirectorySearchState],
  directorySearchState => directorySearchState.directorySearch.hasMore,
);
