import React, { FC, useState } from 'react';
import dayjs from 'dayjs';

import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { IExperienceBasis } from 'models/inner-models';

import BuildingIcon from 'styles/icons/BuildingIcon';
import CloseIcon from 'styles/icons/CloseIcon';
import EditIcon from 'styles/icons/EditIcon';

import { StyledWrap } from './styled';

import Avatar from '../../../Avatar';
import { Checkbox } from '@mui/material';

interface IExperiencesItem {
  disabledEdit?: boolean;
  disabledForAgency: boolean;
  index: number;
  onEditExperiences?: () => void;
  onRemoveExperiences?: () => void;
  item: IExperienceBasis;
  onSelect?: (item: IExperienceBasis, selected: boolean) => void;
  selected?: boolean;
  readOnly?: boolean;
}
const ExperiencesItem: FC<IExperiencesItem> = ({
  disabledEdit,
  disabledForAgency,
  onEditExperiences,
  onRemoveExperiences,
  item,
  onSelect,
  selected,
  readOnly,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (onSelect) {
      onSelect(item, isChecked);
    }
  };

  return (
    <StyledWrap container>
      {onSelect && (
        <Grid xs="auto" alignSelf="center">
          <Checkbox
            size="small"
            checked={selected}
            sx={{ marginLeft: 2, marginRight: 2 }}
            onChange={handleCheckboxChange}
            checkedIcon={<span />}
            icon={<span />}
          />
        </Grid>
      )}
      <Grid xs="auto">
        <Avatar
          size={'small'}
          firstName={item.company?.name || ''}
          lastName={''}
          image={item.company?.logo}
          icon={<BuildingIcon />}
          round
        />
      </Grid>
      <Grid container xs alignSelf="center">
        <Grid lg={3} xs={12}>
          <Typography sx={{ width: 148 }} mx={2} variant="subtitle1">
            <b>{item.company?.name}</b>
          </Typography>
        </Grid>
        <Grid lg xs={12}>
          <Typography mx={2} mb={{ xs: 1, lg: 0 }} sx={{ flexGrow: 1 }} variant="body2">
            {item?.description}
          </Typography>
        </Grid>
        <Grid lg="auto" xs={12}>
          <Typography mx={2} sx={{ width: 145 }} variant="body2">
            {dayjs(item?.startDate).format('MMM YYYY')} -{' '}
            {item?.endDate ? dayjs(item?.endDate).format('MMM YYYY') : 'Present'}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs="auto" alignSelf="center" mr={{ xs: 2, md: 0 }}>
        {onEditExperiences && !readOnly && (
          <IconButton
            disabled={disabledEdit}
            size="small"
            onClick={onEditExperiences}
            sx={{ marginRight: 2 }}
          >
            <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
          </IconButton>
        )}
        {!disabledForAgency && !readOnly && onRemoveExperiences && (
          <IconButton
            size="small"
            data-test="remove-exp"
            disabled={disabledEdit}
            onClick={onRemoveExperiences}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
    </StyledWrap>
  );
};
export default ExperiencesItem;
