import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { ICertificateItem } from 'store/types/certificates';
import { IProductExperienceFull } from 'store/types/consultant';
import { useAppSelector } from 'store/hooks';
import { productsSummaryLoadingSelector } from 'store/selectors/getConsultantSelector';

import { EConsultantEditingModals } from 'models/consts';

import EditIcon from 'styles/icons/EditIcon';

import ExperiencesPanel from './ExperiencesPanel';
import Loader from '../../Loader/Loader';
import { DirectorySearchResult } from 'store/types/directorySearch';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  borderBottom: '1px solid #ebebeb',
}));

interface IConsultantSummary {
  consultant?: DirectorySearchResult;
  consultantKey: string | number;
  expertiseSummary: IProductExperienceFull[];
  isCurrentUser: boolean;
  onOpen?: (open: { type: number; open: boolean }) => void;
  certificates?: ICertificateItem[];
}

const ConsultantSummary: FunctionComponent<IConsultantSummary> = ({
  consultant,
  consultantKey,
  expertiseSummary,
  isCurrentUser,
  onOpen,
  certificates,
}) => {
  const expertiseSummaryLoading = useAppSelector(productsSummaryLoadingSelector(consultantKey));
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={{ xs: 0, md: 3 }}
        mb={3}
      >
        <Typography variant="h4">Expertise summary</Typography>
        {isCurrentUser && onOpen && (
          <IconButton
            data-test="edit-expertise-btn"
            color="tertiary"
            size="small"
            onClick={() => onOpen({ open: true, type: EConsultantEditingModals.PRODUCTS })}
          >
            <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
          </IconButton>
        )}
      </Box>
      <Box>
        {expertiseSummaryLoading && (
          <Box mb={3}>
            <Loader />
          </Box>
        )}
        {expertiseSummary.map(({ experiences, expertise, product }) => (
          <ExperiencesPanel
            key={product.name}
            expanded={expanded === product?.name}
            experiences={experiences}
            expertise={expertise}
            handleChange={handleChange(product.name)}
            consultant={consultant}
            productName={product.name}
            product={product}
            isCertified={
              certificates ? !!certificates?.find(item => item.productId === product.id) : false
            }
          />
        ))}
      </Box>
    </StyledBox>
  );
};

export default ConsultantSummary;
