import React from 'react';
import { useFormContext } from 'react-hook-form';

const HighlightedView = ({ text }: { text: string | null }) => {
  const { getValues } = useFormContext();
  const highlight = getValues().fullText;

  if (!highlight) return <>{text}</>;

  if (text) {
    const highlightWords = highlight.split(/\s+/);
    const regex = new RegExp(`(${highlight}|${highlightWords.join('|')})`, 'gi');
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ||
          highlightWords.some((word: string) => word.toLowerCase() === part.toLowerCase()) ? (
            <span
              key={index}
              style={{ backgroundColor: '#FFD209', paddingLeft: 3, borderRadius: 5 }}
            >
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  }
  return null;
};

export default HighlightedView;
