import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from 'styles/icons/EditIcon';
import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';

import { rateFormat } from 'utils/textFormatter';
import { pluralizeWord } from 'utils/transformers';

import { EConsultantEditingModals } from 'models/consts';

import useConsultantProductExperience from 'hooks/useConsultantProductExperience';
import { DirectorySearchResult } from 'store/types/directorySearch';
import { StyledDescription, StyledStarChip } from '../DirectorySearchConsultantNameView/styled';
import HighlightedView from 'components/DirectorySearch/HighlightedView';

interface IDirectorySearchAboutMeView {
  consultant: DirectorySearchResult;
  isCurrentUser: boolean;
  onOpen: (open: { type: number; open: boolean }) => void;
}

const DirectorySearchAboutMeView: FunctionComponent<IDirectorySearchAboutMeView> = ({
  isCurrentUser,
  onOpen,
  consultant,
}) => {
  const { description } = useConsultantProductExperience(consultant, true);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h3">About me</Typography>
        {/* <Box display="flex" alignItems="center">
          {consultant?.rate && (
            <>
              {!!consultant.rate?.feedbacksCount && (
                <Typography mx={1}>
                  {consultant.rate.feedbacksCount}{' '}
                  {pluralizeWord('Review', consultant.rate.feedbacksCount)}
                </Typography>
              )}
              <StyledStarChip
                color="secondary"
                icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
                variant="filled"
                label={rateFormat(consultant.rate?.totalRate)}
              />
            </>
          )}
          {isCurrentUser && (
            <IconButton
              data-test="edit-profile"
              color="tertiary"
              size="small"
              sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
              onClick={() => onOpen({ open: true, type: EConsultantEditingModals.PROFILE })}
            >
              <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
            </IconButton>
          )}
        </Box> */}
      </Box>
      <StyledDescription>
        {consultant?.highlights?.includes('description') ? (
          <HighlightedView text={description || ''} />
        ) : (
          description
        )}
        {/* {description} */}
      </StyledDescription>
    </>
  );
};

export default DirectorySearchAboutMeView;
