import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const curatedOrderInviteCustomer = createAsyncThunk(
  EOrder.curatedOrderInviteCustomer,
  async (payload: any) => {
    const { data } = await instance.post('draft-orders/invite-customer', payload);

    return data;
  },
);

export default curatedOrderInviteCustomer;
