import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import {
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isAuthorized,
  isConsultantSelector,
  isSelfCustomerSelector,
  isEnterpriseCustomerSelector,
  isSalesSelector,
  isTalentSelector,
} from 'store/selectors/getUserSelector';

import ROUTES from 'router/constants';

import useSafeAreaView from 'components/SafeAreaView';

import AgencyIcon from 'styles/icons/AgencyIcon';
import BriefcaseIcon2 from 'styles/icons/BriefcaseIcon2';
import MessageIcon from 'styles/icons/MessageIcon';
import MyProfile from 'styles/icons/MyProfile';
import SearchIcon from '@mui/icons-material/Search';

import { StyledBottomNavigation, StyledBottomNavigationAction, StyledPaper } from './styled';
import PlusIcon from 'styles/icons/PlusIcon';

type NavbarMenuProps = {
  setShowFeedbackDialog?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavbarMenu: React.FC<NavbarMenuProps> = ({ setShowFeedbackDialog }) => {
  const isAuth = useAppSelector(isAuthorized);
  const isConsultant = useAppSelector(isConsultantSelector);
  const isSelfCustomer = useAppSelector(isSelfCustomerSelector);
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isSales = useAppSelector(isSalesSelector);
  const isTalent = useAppSelector(isTalentSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const isEnterpriseCustomer = useAppSelector(isEnterpriseCustomerSelector);

  const createOrderPath = isSales ? `sales-create-order` : `order-flow/1`; //TODO: change to create sales order page

  const navigate = useNavigate();
  const location = useLocation();

  const { safeAreaPadding } = useSafeAreaView();

  return (
    <StyledPaper
      elevation={0}
      sx={{
        display: { xs: 'block', sm: 'block', lg: 'none' },
        '@media (max-height: 600px)': {
          display: 'none',
        },
      }}
    >
      <StyledBottomNavigation
        showLabels
        value={location.pathname.substring(1) || '/'}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
        sx={{
          height: { xs: 64 + safeAreaPadding.bottom },
          alignItems: 'flex-start',
          pt: '10px',
        }}
      >
        {(isEnterpriseCustomer || isSales) && (
          <StyledBottomNavigationAction
            data-test="create-order-button-header-xs"
            label="Create Order"
            icon={<PlusIcon />}
            value={createOrderPath}
          />
        )}
        {isAuth &&
          !(isSales || isTalent) && [
            <StyledBottomNavigationAction
              data-test="my-orders-link"
              key="orders"
              label="Orders"
              icon={<BriefcaseIcon2 />}
              value="/"
            />,
            <StyledBottomNavigationAction
              data-test="messages-link"
              key="messages"
              label="Message"
              icon={<MessageIcon />}
              value="chat"
            />,
          ]}
        {(isSales || isTalent) && [
          <StyledBottomNavigationAction
            data-test="sales-orders-link"
            key="directory"
            label="Directory"
            icon={<SearchIcon />}
            value="talent-search-landing"
          />,
          <StyledBottomNavigationAction
            data-test="sales-order-link"
            key="salesOrders"
            label="Orders"
            icon={<BriefcaseIcon2 />}
            value="curated-orders-landing"
          />,
        ]}
        {(isAgencyAdmin || isResourceManager) && (
          <StyledBottomNavigationAction
            data-test="team-roaster-link"
            label="Team Roster"
            icon={<AgencyIcon />}
            value="team"
          />
        )}
        {isConsultant && (
          <StyledBottomNavigationAction
            data-test="profile-link"
            label="Profile"
            icon={<MyProfile />}
            value="bee"
          />
        )}
        {isSelfCustomer && (
          <StyledBottomNavigationAction
            data-test="profile-link"
            label="Profile"
            icon={<MyProfile />}
            value="security"
          />
        )}
      </StyledBottomNavigation>
    </StyledPaper>
  );
};

export default NavbarMenu;
