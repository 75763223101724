import React, { useState, useRef } from 'react';
import { Capacitor } from '@capacitor/core';

import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { useAppDispatch } from 'store/hooks';

import { DirectorySearchResult } from 'store/types/directorySearch';
import { StyledPaper, StyledCardActionArea } from './styled';
import DirectorySearchAvatarView from './DirectorySearchAvatarView';
import DirectorySearchDescriptionView from './DirectorySearchDescriptionView';
import DirectorySearchInfoView from './DirectorySearchInfoView';

interface IDirectorySearchItem {
  directorySearchResult: DirectorySearchResult;
  onAnonymousMode?: boolean;
  onOpenPreview: () => void;
}

const DirectorySearchItem: React.FC<IDirectorySearchItem> = ({
  directorySearchResult,
  onAnonymousMode,
  onOpenPreview,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMobileNative = Capacitor.isNativePlatform();

  return (
    <StyledPaper sx={{ mb: 2, borderWidth: { xs: 0, md: 1 } }}>
      <StyledCardActionArea onClick={onOpenPreview}>
        <DirectorySearchAvatarView
          directorySearchResult={directorySearchResult}
          onAnonymousMode={onAnonymousMode}
        />
        <Divider flexItem sx={{ m: 0 }} orientation="vertical" />
        <DirectorySearchDescriptionView
          directorySearchResult={directorySearchResult}
          onAnonymousMode={onAnonymousMode}
        />
      </StyledCardActionArea>
      <Divider flexItem sx={{ mx: 2.5, my: 0, display: { md: 'none' } }} orientation="horizontal" />
      <Divider
        flexItem
        sx={{ mx: 0, my: 2.5, display: { xs: 'none', md: 'block' } }}
        orientation="vertical"
      />
      <div onClick={onOpenPreview}>
        <DirectorySearchInfoView
          directorySearchResult={directorySearchResult}
          onAnonymousMode={onAnonymousMode}
        />
      </div>
    </StyledPaper>
  );
};

export default DirectorySearchItem;
