import { EAgencyRegStep, EConsultantRegStep, ECustomerRegStep } from './consts';
import { IStepperItem } from 'store/types/common';

const ConsultantRegistrationStepper: IStepperItem<EConsultantRegStep>[] = [
  {
    number: EConsultantRegStep.PROFILE,
    text: 'Basic Information',
  },
  {
    number: EConsultantRegStep.PRODUCTS,
    text: 'Your Experience',
  },
  {
    number: EConsultantRegStep.PROFICIENCY,
    text: 'Expertise Level',
  },
  {
    number: EConsultantRegStep.AVAILABILITY,
    text: 'Availability',
  },
  {
    number: EConsultantRegStep.CONNECT,
    text: 'Connect with a Rep',
  },
];

const AgencyConsultantRegistrationStepper: IStepperItem<EConsultantRegStep>[] = [
  {
    number: EConsultantRegStep.PROFILE,
    text: 'Basic Information',
  },
  {
    number: EConsultantRegStep.PRODUCTS,
    text: 'Products & Experience',
  },
  {
    number: EConsultantRegStep.PROFICIENCY,
    text: 'Proficiency',
  },
  {
    number: EConsultantRegStep.AVAILABILITY,
    text: 'Availability',
  },
];

const CustomerRegistrationStepper: IStepperItem<ECustomerRegStep>[] = [
  {
    number: ECustomerRegStep.EXPERTISE,
    text: 'Expertise',
  },
  {
    number: ECustomerRegStep.TIMELINE,
    text: 'Timeline',
  },
  {
    number: ECustomerRegStep.SENIORITY,
    text: 'Seniority',
  },
  {
    number: ECustomerRegStep.RECOMMENDED_EXPERTS,
    text: 'Recommended Experts',
  },
  {
    number: ECustomerRegStep.CONFIRMATION,
    text: 'Confirmation',
  },
];

const AgencyRegistrationStepper: IStepperItem<EAgencyRegStep>[] = [
  {
    number: EAgencyRegStep.PROFILE,
    text: 'Agency information',
  },
];

export {
  ConsultantRegistrationStepper,
  AgencyConsultantRegistrationStepper,
  CustomerRegistrationStepper,
  AgencyRegistrationStepper,
};
