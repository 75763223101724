import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

const finalizeCuratedOrder = createAsyncThunk(EOrder.finalizeCuratedOrder, async (payload: any) => {
  const { data } = await instance.post('draft-orders/finalize', payload);

  return data;
});

export default finalizeCuratedOrder;
