import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from 'store/hooks';
import { isAuthorized } from 'store/selectors/getUserSelector';

import BeeSvg from './bee.svg';
import BackgroundSVG from './background.svg';

import {
  Styled404Wrap,
  StyledAxisInversion,
  StyledBeeWrap,
  StyledHalfZero,
  StyledHiveBG,
  StyledHiveMobileBG,
  StyledNumber,
  StyledTitle,
} from './styled';
import { StyledContainer } from 'layouts/AuthorizedLayout/styled';
import ROUTES from 'router/constants';

const Page404 = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const isAuth = useAppSelector(isAuthorized);

  if (!isAuth) {
    return <Navigate replace to={ROUTES.AUTH} />;
  }

  return (
    <>
      {isDesktop && (
        <>
          <StyledHiveBG />
          <StyledAxisInversion>
            <StyledBeeWrap>
              <img style={{ transform: 'scaleX(-1)' }} src={BeeSvg} />
            </StyledBeeWrap>
          </StyledAxisInversion>
        </>
      )}
      {!isDesktop && (
        <StyledHiveMobileBG>
          <img src={BackgroundSVG} alt="bee" />
        </StyledHiveMobileBG>
      )}
      <StyledContainer maxWidth="xxl" fixed={matches} sx={{ zIndex: 3 }}>
        <StyledTitle variant="h1">
          Ooops!
          <br />
          It seems that you are lost
        </StyledTitle>
        <Styled404Wrap mb={{ xs: 0.5, sm: 2.5, lg: 7 }}>
          <StyledNumber component="span">4</StyledNumber>
          <StyledHalfZero sx={{ ml: 2 }} />
          <StyledHalfZero sx={{ transform: { xs: 'rotateY(180deg) translateX(8px)' }, mr: 2 }} />
          <StyledNumber component="span">4</StyledNumber>
          {!isDesktop && <img src={BeeSvg} alt="bee" />}
        </Styled404Wrap>
        <Typography sx={{ fontSize: '1.125rem' }} order={2}>
          The page doesn’t exist or was removed.
          <br /> We suggest you back to home
        </Typography>
        <Box mt={{ xs: 5, lg: 10 }} order={4} sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
          <Button onClick={() => navigate('/')} variant="contained" color="secondary">
            Back to Home
          </Button>
        </Box>
      </StyledContainer>
    </>
  );
};

export default Page404;
