import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { RavenRatingIcon } from 'styles/icons/RavenRatingIcon';
import { RavenIcon } from 'styles/icons/RavenIcon';
import {
  StyledFavoriteIcon,
  StyledRavenRated,
  StyledRavenRatedText,
  StyledRecommended,
} from './styled';
import {
  StyledBadgeImage,
  StyledMobileBadgeImage,
  StyledStarChip as StyledStarChip2,
} from 'components/AgencyComponents/AgencyCard/styled';
import {
  StyledDescription,
  StyledStarChip,
} from 'components/ConsultantComponents/HeaderConsultant/styled';

import { pluralizeWord } from 'utils/transformers';

import useDeviceInfo from 'hooks/useDeviceInfo';

import { IAgency } from 'store/types/agency';

import Avatar from 'components/Avatar';
import AgencyNameBlock from './AgencyNameBlock';
import AgencyConsultantSummary from './AgencyConsultantSummary';
import AgencyConsultantSkills from './AgencyConsultantSkills';
import AgencyConsultantsList from './AgencyConsultantsList';
import StarFilledIcon3 from 'styles/icons/StarFilledIcon3';
import { UKGPartnerPreviewBadge } from '../../../styles/icons/UKGPartnerPreviewBadge';
import { UKGPartnerBadge } from '../../../styles/icons/UKGPartnerBadge';
import { UKGPartnerMobileBadge } from '../../../styles/icons/UKGPartnerMobileBadge';

export interface IAgencyView {
  agency: IAgency;
  onSelectPreview?: (worker: any) => void;
  isSelectedExpert?: boolean;
  isSalesOrTalent?: boolean;
  onAnonymousMode?: boolean;
}

const AgencyView: React.FunctionComponent<IAgencyView> = ({
  agency,
  onSelectPreview,
  isSelectedExpert,
  isSalesOrTalent,
  onAnonymousMode,
}) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deviceType } = useDeviceInfo();

  const isMobile = deviceType === 'mobile' && mobileScreen;

  const agencyStyles = JSON.parse(agency.style);
  const [activeConsultant, setActiveConsultant] = useState<number | undefined>(
    agency.matchedConsultants.data[0].userId || agency?.topMatchedConsultant?.id,
  );

  const handleSwitchConsultant = (userId: number) => {
    if (userId !== activeConsultant) {
      setActiveConsultant(userId);
    }
  };

  const ravenRated = false;
  const recommended = false;
  const ukgCertified = false;

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid
        container
        rowSpacing={3}
        xs={12}
        sx={{
          padding: { xs: 2, md: 5 },
          backgroundColor: agencyStyles?.backgroundColor || '#D4D4D4',
          width: 'auto',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {ukgCertified &&
          (isMobile ? (
            <StyledMobileBadgeImage>
              <UKGPartnerMobileBadge />
            </StyledMobileBadgeImage>
          ) : (
            <StyledBadgeImage>
              <UKGPartnerPreviewBadge />
            </StyledBadgeImage>
          ))}
        <Grid
          xs="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            paddingLeft: isMobile ? 0 : '90px',
          }}
        >
          <Avatar
            image={agency.logo}
            firstName={agency.name || ''}
            size={(isMobile && 'medium') || 'large'}
          />
          {/*TODO: do we still need it*/}
          {/*TODO: add logic for showing after BE ready*/}
          {ravenRated && (
            <StyledRavenRated>
              <RavenIcon />
              <StyledRavenRatedText>Raven Rated</StyledRavenRatedText>
            </StyledRavenRated>
          )}
          {recommended && (
            <StyledRecommended>
              <StyledFavoriteIcon />
              <StyledRavenRatedText>Recommended Expert</StyledRavenRatedText>
            </StyledRecommended>
          )}
        </Grid>
        <Grid
          xs
          sx={{
            color: agencyStyles?.textColor || '#000',
            paddingX: isMobile ? 2 : 5,
            alignSelf: 'center',
          }}
        >
          <AgencyNameBlock agency={agency} />
        </Grid>
        {!isSalesOrTalent && (
          <Grid xs="auto" display={{ xs: 'none', sm: 'block' }}>
            <Button
              color={(!isSelectedExpert && 'secondary') || 'primary'}
              onClick={() => onSelectPreview && onSelectPreview({ agency })}
              variant="contained"
              disabled={isSalesOrTalent}
            >
              {isSelectedExpert ? 'Selected' : 'Select'}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ padding: { xs: 2, md: 5 } }}>
        <Grid xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
            mt={isMobile ? 2 : 0}
          >
            <Typography variant="h3">About</Typography>
            {/*TODO: add logic for showing after BE ready*/}
            {ravenRated ? (
              <Box display="flex" alignItems="center">
                <StyledStarChip icon={<RavenRatingIcon />} label={`4/5`} variant="filled" />
                <Typography mx={1}>101 {pluralizeWord('Review', 101)}</Typography>
              </Box>
            ) : (
              <StyledStarChip2
                icon={<StarFilledIcon3 sx={{ fontSize: '1rem' }} />}
                label={`4/5`}
                variant="filled"
              />
            )}
          </Box>
          <StyledDescription>{agency.description}</StyledDescription>
          {!!agency?.companyTags?.length && (
            <Box sx={{ mt: 3 }}>
              {agency.companyTags.map((item: any) => (
                <Chip
                  sx={{ mr: 1.5, mb: 2 }}
                  key={item.id}
                  label={item.name}
                  variant="outlined"
                  size="small"
                />
              ))}
            </Box>
          )}
        </Grid>
        <Grid xs={12} spacing={0}>
          <Divider sx={{ mb: 3 }} />
        </Grid>
        <Grid xs={12} mb={3} sx={{ position: 'relative' }}>
          <AgencyConsultantsList
            agency={agency}
            onSelect={handleSwitchConsultant}
            activeConsultant={activeConsultant}
            isSalesOrTalent={isSalesOrTalent}
            onAnonymousMode={onAnonymousMode}
          />
        </Grid>
        <Grid container xs={12} spacing={2}>
          <Grid xs={12}>
            <AgencyConsultantSummary activeConsultant={activeConsultant} />
          </Grid>
          <Grid xs={12}>
            <AgencyConsultantSkills activeConsultant={activeConsultant} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AgencyView;
