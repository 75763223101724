import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { getMeFullSelector } from 'store/selectors/getUserSelector';

import { redirectBasedOnUserTypeStatus } from 'utils/redirectUtil';

const HomeRedirect = () => {
  const userFull = useAppSelector(getMeFullSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (userFull) {
      redirectBasedOnUserTypeStatus(userFull, navigate);
    }
  }, [userFull, navigate]);

  return null;
};

export default HomeRedirect;
