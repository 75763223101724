import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { IBasicConsultant, IWorker } from 'store/types/consultant';
import { IAgency } from 'store/types/agency';
import { useAppDispatch } from 'store/hooks';
import downloadAnyConsultantResume from 'store/query/consultant/downloadAnyConsultantResume';
import fetchProfileLink from 'store/query/talent-search/fetchProfileLink';
import { DirectorySearchResult } from 'store/types/directorySearch';

import isDev, { isLocal, isPreStaging, isProd, isStaging } from 'helpers/environmentDetector';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';
import HighlightedView from 'components/DirectorySearch/HighlightedView';
import HighlightedButton from 'components/DirectorySearch/HighlightedButton';

interface IDirectorySearchInfoView {
  directorySearchResult: DirectorySearchResult;
  onAnonymousMode?: boolean;
}

const DirectorySearchInfoView: React.FC<IDirectorySearchInfoView> = ({
  directorySearchResult,
  onAnonymousMode,
}) => {
  const dispatch = useAppDispatch();
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [openClipboard, setOpenClipboard] = useState(false);

  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMobileNative = Capacitor.isNativePlatform();

  const handleClick = async (clipboardText: string) => {
    try {
      if (isMobileNative) {
        await Clipboard.write({ string: clipboardText });
        setOpenClipboard(true);
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(clipboardText);
        setOpenClipboard(true);
      } else {
        throw new Error('Clipboard API is unavailable');
      }
    } catch (err) {
      ToastService.error(
        ToastMessage({
          title: 'Error',
          body: 'Unable to copy to clipboard. Please try again later!',
        }),
      );
      console.error('Failed to copy with clipboard:', err);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenClipboard(false);
  };

  const handleCVDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const fileId: number | null = directorySearchResult?.cvFileId;
      if (fileId) {
        const data = await dispatch(downloadAnyConsultantResume(fileId)).unwrap();
        if (data && data.url) {
          const response = await fetch(data.url);
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const fileName = data.originalName || 'cv-file-name.pdf';
          if (isMobileNative) {
            await Browser.open({ url: data.url });
          } else {
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          window.URL.revokeObjectURL(downloadUrl);
        }
      }
    } catch (error) {
      console.error('Failed to download CV:', error);
    }
  };

  const generateLinkedInUrl = (linkedInValue: string | undefined) => {
    const baseUrl = 'https://www.linkedin.com/in/';
    if (linkedInValue) {
      if (linkedInValue.includes(baseUrl) || linkedInValue.includes('https://linkedin.com/in/')) {
        return linkedInValue;
      }
    }
    return `${baseUrl}${linkedInValue}`;
  };

  const handleGetConsultantLink = async () => {
    const baseUrlMap = {
      local: 'http://app-dev.myworkerbee.com/',
      dev: 'http://app-dev.myworkerbee.com/',
      staging: 'https://app-stage.myworkerbee.com/',
      preStaging: 'https://app-pre-stage.myworkerbee.com/',
      prod: 'https://app.myworkerbee.com/',
    };

    const getEnvironment = () => {
      if (isProd()) return 'prod';
      if (isStaging()) return 'staging';
      if (isPreStaging()) return 'preStaging';
      if (isDev() || isLocal()) return 'dev';
      return 'prod';
    };
    const baseUrl = baseUrlMap[getEnvironment()];

    if (!directorySearchResult?.userId) {
      ToastService.error(
        ToastMessage({
          title: 'Error',
          body: 'User id is missing',
        }),
      );
      return '';
    }

    if (!directorySearchResult.uidHashCode) {
      try {
        const data = await dispatch(
          fetchProfileLink({ userId: directorySearchResult.userId }),
        ).unwrap();
        if (data?.uidHashCode) {
          return `${baseUrl}sharable-specialist/${data.uidHashCode}`;
        } else {
          ToastService.error(
            ToastMessage({
              title: 'Error',
              body: 'Failed to retrieve profile link for consultant',
            }),
          );
          return '';
        }
      } catch (error) {
        console.error('Error fetching profile link:', error);
        ToastService.error(
          ToastMessage({
            title: 'Error',
            body: 'Unable to copy to clipboard. Please try again later!',
          }),
          console.error('Failed to create hashcode:', error),
        );
        return '';
      }
    }
    return `${baseUrl}sharable-specialist/${directorySearchResult.uidHashCode}`;
  };

  return (
    <Box
      display="flex"
      flexDirection={!isiPadOrMobile ? 'column' : 'column-reverse'}
      justifyContent="space-between"
      px={2.5}
      py={{ xs: 1.5, md: 2.5 }}
      sx={{ textAlign: 'center', width: { xs: '100%', md: '376px' } }}
    >
      <Box>
        {
          <>
            {!onAnonymousMode && (
              <>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography mr={7} fontSize={'18px'} fontWeight={500}>
                    Email:
                  </Typography>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={400}
                    textAlign={'left'}
                    sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                  >
                    {directorySearchResult?.highlights?.includes('email') ? (
                      <HighlightedView text={directorySearchResult?.email || ''} />
                    ) : (
                      directorySearchResult?.email
                    )}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography mr={6} fontSize={'18px'} fontWeight={500}>
                    Phone:
                  </Typography>
                  <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                    {directorySearchResult.mobilePhone}
                  </Typography>
                </Box>
              </>
            )}
            {directorySearchResult?.availabilities &&
              directorySearchResult?.availabilities.length > 0 && (
                <>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography mr={2} fontSize={'18px'} fontWeight={500}>
                      Availability:
                    </Typography>
                    <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                      {directorySearchResult?.availabilities[0]?.availabilityWeek}h/wk
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography mr={4} fontSize={'18px'} fontWeight={500}>
                      Updated:
                    </Typography>
                    <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                      {directorySearchResult?.availabilities[0]?.updatedAt
                        ? dayjs(directorySearchResult?.availabilities[0]?.updatedAt).format(
                            'MM/DD/YYYY',
                          )
                        : ''}
                    </Typography>
                  </Box>
                </>
              )}
            {directorySearchResult.approvedAt && (
              <Box display={'flex'} flexDirection={'row'}>
                <Typography mr={6} fontSize={'18px'} fontWeight={500}>
                  {'Vetted:'}
                </Typography>
                <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                  {dayjs(directorySearchResult.approvedAt).format('MM/DD/YYYY')}
                </Typography>
              </Box>
            )}
            <Box display={'flex'} flexDirection={'row'}>
              <Typography mr={4} fontSize={'18px'} fontWeight={500}>
                Location:
              </Typography>
              <Typography fontSize={'18px'} fontWeight={400} textAlign={'left'}>
                {directorySearchResult?.highlights?.includes('location') ? (
                  <HighlightedView text={directorySearchResult?.location || ''} />
                ) : (
                  directorySearchResult?.location
                )}
              </Typography>
            </Box>
          </>
        }
      </Box>
      {
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          mt={2}
          mb={isiPadOrMobile ? 2 : undefined}
        >
          <Box mr={1}>
            <HighlightedButton
              data-test="select-button-agency-card-md"
              color="secondary"
              onClick={handleCVDownload}
              variant="contained"
              disabled={!directorySearchResult.cvFileId}
              sx={{ width: '80px', height: '33px' }}
              isHighlighted={directorySearchResult.highlights?.includes('cvText')}
            >
              Resume
            </HighlightedButton>
          </Box>
          <Box mr={1}>
            <Button
              data-test="select-button-agency-card-md"
              color={'secondary'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleClick(generateLinkedInUrl(directorySearchResult.linkedInLink || ''));
              }}
              variant="contained"
              disabled={!directorySearchResult.linkedInLink}
              sx={{ width: '80px', height: '33px' }}
            >
              LinkedIn
            </Button>
          </Box>
          <Box>
            <Button
              data-test="select-button-agency-card-md"
              color={'secondary'}
              onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleClick(await handleGetConsultantLink());
              }}
              variant="contained"
              sx={{ width: '133px', height: '33px' }}
            >
              Copy Profile Link
            </Button>
            <Snackbar
              open={openClipboard}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleClose}
                sx={{
                  backgroundColor: '#FFCA28',
                  width: '100%',
                  color: '#000',
                  marginTop: isiPadOrMobile ? 10 : undefined,
                }}
              >
                Copied to clipboard!
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      }
    </Box>
  );
};

export default DirectorySearchInfoView;
