import React, { FunctionComponent, useMemo, useState } from 'react';
import dayjs from 'utils/dayjs';
import { useNavigate } from 'react-router-dom';

import { EOrderFilter, ERole } from 'models/consts';
import { IBasicOrders } from 'store/types/order';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeRoleSelector, getMeSelector } from 'store/selectors/getUserSelector';
import { getAgencyUsersSelector } from 'store/selectors/getAgencySelector';

import {
  StyledPaper,
  StyledItemHeader,
  StyledLink,
  StyledTimelineWrap,
  StyledColaborator,
} from './styled';

import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { LoadingButton } from '@mui/lab';

import Menu from 'components/Order/OrderItem/Menu';
import Avatar from 'components/Avatar';
import { statusText } from 'utils/textFormatter';
import ROUTES from 'router/constants';
import finalizeCuratedOrder from 'store/query/curated-order/finalizeCuratedOrder';
import fetchCuratedOrdersList from 'store/query/curated-order/fetchCuratedOrdersList';

export interface ISalesOrTalentOrderItemProps {
  order: IBasicOrders;
}

const CuratedOrderItem: FunctionComponent<ISalesOrTalentOrderItemProps> = ({ order }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const users = useAppSelector(getAgencyUsersSelector);
  const role = useAppSelector(getMeRoleSelector);

  const user = useAppSelector(getMeSelector);

  const totalHours = order.timeLine?.allHours;

  //Required to use the Menu component
  const handleApproveDraft = () => {};

  const handleCreateOrder = () => {
    setIsFetching(true);
    dispatch(
      finalizeCuratedOrder({
        id: order.id,
      }),
    )
      .unwrap()
      .then(() => {
        setIsFetching(true);
        dispatch(fetchCuratedOrdersList());
      });
  };

  const onOrderEdit = () => {
    navigate(ROUTES.SALES_CREATE_ORDER, {
      state: {
        order: order,
      },
    });
  };

  const getTotalBudget = useMemo(() => {
    if (order && order.customerRate && totalHours) {
      return `$${((totalHours * order.customerRate) / 100).toLocaleString()}`;
    }
    return 'N/A';
  }, [order, totalHours]);

  return (
    <StyledPaper square>
      <StyledItemHeader display="flex" alignItems="start">
        <Box display="flex" alignItems="center" flexGrow={1} justifyContent="space-between">
          <Typography fontSize={18} fontWeight={500} onClick={() => onOrderEdit()}>
            {order?.name}
          </Typography>
          <Menu
            order={order}
            filter={EOrderFilter.PENDING}
            handleApproveDraft={handleApproveDraft}
            onOrderEdit={onOrderEdit}
          />
        </Box>
      </StyledItemHeader>
      <Divider sx={{ my: 1 }} />
      <Box
        display={'flex'}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        minHeight={'30px'}
      >
        {order?.hashCode && (
          <>
            <Typography color="text.secondary" gutterBottom>
              Order#{' '}
              <Typography color="text.primary" component="span">
                {order?.hashCode}
              </Typography>
            </Typography>
            <Chip
              sx={{}}
              size="small"
              label={statusText(order?.status)}
              className={order?.status}
            />
          </>
        )}
      </Box>
      <StyledTimelineWrap mb={2} px={1.5} py={2.5}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography component="span">
            <strong>Total hours</strong>
          </Typography>
          <Typography component="span">{totalHours ? `${totalHours} hours` : 'N/A'}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography component="span">
            <strong>Customer Rate</strong>
          </Typography>
          <Typography component="span">
            {order?.customerRate ? `$${order.customerRate / 100}` : 'N/A'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography component="span">
            <strong>Specialist Rate</strong>
          </Typography>
          <Typography component="span">
            {order?.specialistRate ? `$${order.specialistRate / 100}` : 'N/A'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography component="span">
            <strong>Total Budget</strong>
          </Typography>
          <Typography component="span">{getTotalBudget}</Typography>
        </Box>
      </StyledTimelineWrap>
      <Box display="flex" alignItems="center" mb={2} ml={1} minHeight={'49px'}>
        <Typography color={'#5D5D5E'} fontSize={'14px'} fontWeight={400} mr={1}>
          Customer
        </Typography>
        {order?.customer ? (
          <>
            <Avatar
              image={
                order?.customer?.customer?.avatarUrl ||
                order?.customer?.customer?.companies[0]?.logo
              } //TODO: see if this works
              firstName={order?.customer?.firstName || order?.customer?.initials}
              lastName={order?.customer?.lastName || undefined}
            />
            <Box>
              <StyledColaborator ml={1.5} fontWeight={500}>
                {order?.customer?.customer?.companies[0]?.name}
              </StyledColaborator>
              <StyledColaborator ml={1.5}>
                {order?.customer?.firstName
                  ? `${order?.customer?.firstName} - ${order?.customer?.lastName}`
                  : 'N/A'}
              </StyledColaborator>
            </Box>
          </>
        ) : (
          <Typography>N/A</Typography>
        )}
      </Box>

      <Box display="flex" justifyContent="center" px={2}>
        {order.status !== 'active' && (
          <LoadingButton
            color="secondary"
            fullWidth
            variant="contained"
            disabled={order?.status !== 'ready_to_finalize' || user.role.name === ERole.TALENT}
            onClick={handleCreateOrder}
            loading={isFetching}
          >
            Create Order
          </LoadingButton>
        )}
      </Box>
    </StyledPaper>
  );
};

export default CuratedOrderItem;
