import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

import { IExtendCategoryWithProducts } from '../../../store/types/categoryProducts';

import { StyledRootBox, StyledHeading1, StyledHeading2, StyledSearchButton } from './styled';

import ROUTES from 'router/constants';

import ProductAutoComplete from '../ProductAutoComplete';

const HEADER_TEXT = 'Find a Workerbee';

const SUBHEADER_TEXT = 'Search the Workerbee Directory to find the right expertise you need.​';

const PLACEHOLDER_LONG = 'Search by name, email, product, types of work, modules, etc.';

const PLACEHOLDER_SHORT = 'Search by name, email, product, modules, etc.';

const TalentLanding: FunctionComponent = () => {
  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchValue, setSearchValue] = useState<IExtendCategoryWithProducts | string | null>(null);

  const onSearch = () => {
    const navigationState =
      typeof searchValue === 'string'
        ? { searchString: searchValue }
        : { searchProduct: searchValue };
    navigate(ROUTES.DIRECTORY_SEARCH_RESULTS, { state: navigationState });
  };

  return (
    <StyledRootBox>
      <StyledHeading1>{HEADER_TEXT}</StyledHeading1>

      <StyledHeading2>{SUBHEADER_TEXT}</StyledHeading2>

      <ProductAutoComplete
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearch={onSearch}
        placeholderText={isMobile ? PLACEHOLDER_SHORT : PLACEHOLDER_LONG}
        sx={{ marginTop: 4 }}
      />

      <StyledSearchButton variant="contained" color="secondary" onClick={() => onSearch()}>
        Search
      </StyledSearchButton>
    </StyledRootBox>
  );
};

export default TalentLanding;
