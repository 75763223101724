import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Typography,
  CircularProgress,
  Grid,
  Button,
  useMediaQuery,
  Drawer,
} from '@mui/material';

import useSafeAreaView from 'components/SafeAreaView';
import DirectorySearchItem from 'components/DirectorySearch/DirectorySearchItem';
import DirectoryFilter from './DirectoryFilter';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getDirectorySearchItemsSelector,
  getDirectorySearchCountSelector,
  getDirectorySearchLoadingSelector,
  getDirectorySearchHasMoreSelector,
} from 'store/selectors/getDirectorySearchSelector';
import directorySearchQuery from 'store/query/directory-search/directory-flexsearch';
import { DirectorySearchResult } from 'store/types/directorySearch';

import theme from 'theme';
import mobile from 'store/reducers/mobile';
import { StyledCloseIcon, StyledDrawerBtn } from './styled';
import CloseIcon from 'styles/icons/CloseIcon';
import DirectorySearchConsultantView from 'components/DirectorySearch/DirectorySearchConsultantView';

const DirectorySearchResults: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const isiPadOrMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));

  const { safeAreaPadding } = useSafeAreaView();

  const [onAnonymousMode, setOnAnonymousMode] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSpecialistData, setOpenSpecialistData] = useState<DirectorySearchResult | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const items = useAppSelector(getDirectorySearchItemsSelector);
  const countAll = useAppSelector(getDirectorySearchCountSelector);
  const loading = useAppSelector(getDirectorySearchLoadingSelector);
  const hasMore = useAppSelector(getDirectorySearchHasMoreSelector);

  const methods: UseFormReturn<any> = useForm({
    mode: 'onChange',
    defaultValues: {
      fullText: state?.searchString || '',
      product: state?.searchProduct || null,
      'expertises:productId': state?.searchProduct?.id || null,
      typeId: 'n',
    },
  });

  const { getValues } = methods;

  const runDirectorySearch = (doPaginate: boolean) => {
    const values = getValues();

    //Workaround for autocomplete multiple values
    ['tags'].forEach(key => {
      if (values[key] && Array.isArray(values[key])) {
        values[key] = values[key].map((item: any) => item.name);
      }
    });

    ['expertises:modulesWorks:moduleId', 'expertises:modulesWorks:workTypeId'].forEach(key => {
      if (values[key] && Array.isArray(values[key])) {
        values[key] = values[key].map((item: any) => item.id);
      }
    });

    dispatch(directorySearchQuery({ values, doPaginate }));
  };

  const newSearch = () => runDirectorySearch(false);

  const nextPage = () => runDirectorySearch(true);

  useEffect(() => {
    newSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnonymousMode = (isAnonymous: boolean) => {
    setOnAnonymousMode(isAnonymous);
  };

  const toggleDrawer = (open: boolean, item: DirectorySearchResult | null, index: number) => {
    setOpen(open);
    setOpenSpecialistData(item);
    setCurrentIndex(index);
  };

  const handleNext = () => {
    if (currentIndex + 1 === countAll) return;
    if (currentIndex + 1 === items.length) {
      nextPage();
    } else {
      setCurrentIndex(prevIndex => (prevIndex + 1) % items.length);
    }
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + items.length) % items.length);
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <DirectoryFilter onSubmit={newSearch} onAnonymousMode={handleAnonymousMode} />
        <Divider />
        {!!items?.length && (
          <Box>
            <Typography variant={!mobile ? 'h6' : 'body1'} mt={2} mb={2}>
              {countAll} Workerbee Matches:
            </Typography>
            <Box sx={{ paddingBottom: isiPadOrMobile ? (!hasMore ? 10 : undefined) : undefined }}>
              {items.map((item: DirectorySearchResult, index: number) => (
                <DirectorySearchItem
                  key={index}
                  directorySearchResult={item}
                  onAnonymousMode={onAnonymousMode}
                  onOpenPreview={() => {
                    var selection = window.getSelection();
                    if (selection == null || selection.toString().length === 0) {
                      //We'll open the drawer only if the user isn't trying to select text from the card preview
                      toggleDrawer(true, item, index);
                    }
                  }}
                />
              ))}
            </Box>
            {hasMore && (
              <Box display="flex" justifyContent="center" mt={5} mb={isiPadOrMobile ? 14 : 4}>
                <LoadingButton
                  loading={loading}
                  color="secondary"
                  onClick={nextPage}
                  variant="outlined"
                >
                  Show me more workerbees
                </LoadingButton>
              </Box>
            )}
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: { xs: '15%', md: '5%' },
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {items.length === 0 && !loading && (
          <Typography mt={8}>
            Use the Search Bar above to find the right expertise you need
          </Typography>
        )}
        {isiPadOrMobile && (
          <Grid
            sx={{
              position: 'fixed',
              bottom: 64,
              right: 0,
              width: '100%',
              height: `${58 + safeAreaPadding.bottom}px`,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: '100%',
                height: '100%',
                paddingBottom: `${safeAreaPadding.bottom || 6}px`,
              }}
              onClick={newSearch}
            >
              Search
            </Button>
          </Grid>
        )}
        <Drawer
          anchor={(tablet && 'bottom') || 'right'}
          open={open}
          onClose={() => toggleDrawer(false, null, 0)}
          PaperProps={{
            sx: {
              width: { lg: '65%' },
              height: '100%',
              top: 0,
            },
          }}
        >
          {openSpecialistData && (
            <Box
              sx={{
                p: { xs: 2, md: 5 },
              }}
              role="presentation"
              mt={safeAreaPadding.top}
            >
              <StyledDrawerBtn>
                <StyledCloseIcon
                  aria-label="close-presentation"
                  onClick={() => toggleDrawer(false, null, 0)}
                  onMouseDown={() => toggleDrawer(false, null, 0)}
                >
                  <CloseIcon />
                </StyledCloseIcon>
              </StyledDrawerBtn>

              <DirectorySearchConsultantView
                consultant={items[currentIndex]}
                onAnonymousMode={onAnonymousMode}
                onSelectPreview={worker => {}}
                currentIndex={currentIndex}
                totalResults={items.length}
                countAll={countAll}
                hasMoreResults={hasMore}
                onNext={handleNext}
                onPrev={handlePrev}
              />
            </Box>
          )}
        </Drawer>
      </Box>
    </FormProvider>
  );
};

export default DirectorySearchResults;
