import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';

interface HighlightedButtonProps extends ButtonProps {
  isHighlighted?: boolean;
}

const HIGHLIGHTED_BOX_STYLE = {
  backgroundColor: '#FFD209',
  px: '4px',
  lineHeight: '22px',
  color: 'black',
};

const HighlightedButton: React.FC<HighlightedButtonProps> = ({
  isHighlighted,
  children,
  ...props
}) => {
  return (
    <Button {...props}>
      <Box sx={isHighlighted ? HIGHLIGHTED_BOX_STYLE : {}}>{children}</Box>
    </Button>
  );
};

export default HighlightedButton;
