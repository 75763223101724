import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import deepmerge from 'deepmerge';
import { useParams } from 'react-router-dom';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';
import debounce from 'lodash/debounce';

import 'react-phone-number-input/style.css';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button, useMediaQuery } from '@mui/material';

import { DEFAULT_USER_LANGUAGE, NAME_REGEX } from 'models/consts';
import { IConsultantRegister } from 'store/types/inner-models';
import { stringToLowerCaseWithReplaceSpace } from 'utils/transformers';

import instance from 'store/middlewares/api';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateBasicInfo from 'store/query/consultant/updateBasicInfo';
import updateConsultantInfo from 'store/query/consultant/updateConsultantInfo';
import addUserLanguages from 'store/query/user/addUserLanguages';
import removeUserLanguages from 'store/query/user/removeUserLanguages';
import updateUserLanguages from 'store/query/user/updateUserLanguages';
import saveAvatar from 'store/query/consultant/saveAvatar';

import { getMeSelector } from 'store/selectors/getUserSelector';

import CameraIcon from 'styles/icons/CameraIcon';
import { StyledInputLabel, StyledFormGroup } from 'components/CustomFields/styled';
import { StyledH1Mobile } from '../../styled';

import { IBasicUser } from 'store/types/user';
import { IUserLanguages } from 'store/types/language';
import { IBasicConsultant, IProfileForm } from 'store/types/consultant';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import { BootstrapInput, PhoneNumber } from 'components/CustomFields';
import CustomTooltip from 'components/CustomTooltip';
import LocationInput from 'components/CustomFields/LocationInput';
import UploadPhoto from 'components/CustomFields/UploadPhoto';

import Languages from './Languages';
import NoBusinessSetup from 'components/ConsultantComponents/NoBusinessSetup';
import CVFileUpload from 'components/CVFileUpload';
import { DownloadIcon } from 'styles/icons/DownloadIcon';
import downloadCV from 'store/query/consultant/downloadCV';

// This is the Agency Consultant or any regular Consultant Signup flow and profile
const ConsultantFullProfile: FunctionComponent<IConsultantRegister> = ({
  isModal,
  onSubmitted,
  showIntro,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };

  const { id } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'xxl'));
  const {
    clearErrors,
    control,
    formState: { errors, touchedFields, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    trigger,
    getValues,
  } = useFormContext<IProfileForm>();

  const defaultMobilePhone = getValues()?.mobilePhone;
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(id || user?.id)) as IBasicConsultant;
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const businessSetup = useWatch({
    control,
    name: 'businessSetup',
    defaultValue: consultant?.businessSetup || undefined,
  });

  const mobilePhone = useWatch({
    control,
    name: 'mobilePhone',
    defaultValue: defaultMobilePhone?.nationalNumber
      ? defaultMobilePhone
      : {
          country:
            parsePhoneNumber(consultant?.mobilePhone || '')?.country ||
            consultant?.countryIso ||
            'US',
          countryCallingCode: parsePhoneNumber(consultant?.mobilePhone || '')?.countryCallingCode,
          nationalNumber: parsePhoneNumber(consultant?.mobilePhone || '')?.nationalNumber,
        },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce(async (value: string) => {
      if (consultant?.nickName !== value && !isSubmitting) {
        try {
          await instance.get(`consultant/check-by/${value}`);
          clearErrors('nickName');
        } catch (e: any) {
          setError('nickName', { type: 'custom', message: e.response?.data?.message });
          return e.message;
        }
      }
      return true;
    }, 500),
    [],
  );

  const onSubmit: SubmitHandler<any> = async ({
    firstName,
    lastName,
    displayName,
    nickName,
    mobilePhone,
    languages,
    location,
    image,
    ...props
  }) => {
    if (touchedFields.firstName || touchedFields.lastName || touchedFields.displayName) {
      await dispatch(
        updateBasicInfo({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          displayName: displayName.trim(),
        }),
      );
    }

    if (
      touchedFields.description ||
      touchedFields.linkedInLink ||
      touchedFields.mobilePhone?.nationalNumber ||
      touchedFields.mobilePhone?.country ||
      location ||
      touchedFields.nickName ||
      touchedFields.shortDescriptions
    ) {
      await dispatch(
        updateConsultantInfo({
          location: location.label,
          nickName: nickName,
          mobilePhone: [
            '+',
            getCountryCallingCode(mobilePhone.country),
            mobilePhone.nationalNumber,
          ].join(''),
          businessSetup,
          description: props.description,
          linkedInLink: props.linkedInLink,
          shortDescriptions: props.shortDescriptions,
          isPublic: props.isPublic,
          cvFileId: props.cv ? props.cv : null,
          countryIso: props.countryIso,
        }),
      );
    }
    if (image) {
      await dispatch(saveAvatar({ image, userId: user?.id, key: id || user?.id }));
    }
    languageDataSaving(languages, touchedFields.languages);

    onSubmitted && onSubmitted();
  };

  const languageDataSaving = (languages: IUserLanguages[], touchedLanguages: any) => {
    if (touchedLanguages) {
      const newLangs = languages.filter(({ id }) => !id);
      if (newLangs.length) {
        dispatch(
          addUserLanguages({
            languages: newLangs.map(({ langId, level }) => ({ langId, level })),
            userId: id || user?.id,
          }),
        );
      }
      const updatedLangs = languages.filter(({ id }, index) => id && touchedLanguages[index]);
      if (updatedLangs.length) {
        dispatch(
          updateUserLanguages({
            languages: updatedLangs,
            userId: id || user?.id,
          }),
        );
      }
    }
    if (consultant?.languages) {
      const deletedLang = consultant?.languages.filter(
        item => !languages.find(({ id }) => id === item.id),
      );
      if (deletedLang.length) {
        dispatch(
          removeUserLanguages({
            ids: deletedLang?.map(({ id }) => id),
            userId: id || user?.id,
          }),
        );
      }
    }
  };

  const handleUploadPhoto = (blob: Blob) => {
    dispatch(saveAvatar({ image: blob, userId: user?.id, key: id || user?.id }));
  };

  const handleDownloadCV = async () => {
    try {
      await dispatch(downloadCV())
        .unwrap()
        .then((data: any) => {
          if (data && data.url) {
            if (isiPadOrMobile) {
              const link = downloadLinkRef.current;
              if (link) {
                link.href = data.url;
                link.download = 'cv-file-name';
                link.click();
              }
            } else {
              window.open(data.url);
            }
          }
        });
    } catch (error) {
      // console.log('error', error);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!consultant?.avatarUrl) {
          stepsList.push({
            target: '.profile-picture-input',
            title: 'Profile Picture',
            content:
              'This photo is visible to clients. Upload a photo that you feel represents you. It can be a professional headshot or any image that maintains your comfort with privacy.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!user?.displayName) {
          stepsList.push({
            target: '.display-name-input',
            title: 'Display Name',
            content: 'This name is visible to clients.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.shortDescriptions) {
          stepsList.push({
            target: '.short-description-input',
            title: 'Headline',
            content:
              'What do you do? Provide a professional title or role description that summarizes your expertise concisely.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.description) {
          stepsList.push({
            target: '.description-input',
            title: 'About Me',
            content:
              'What’s your story? Showcase your professional strengths and highlight your accomplishments. Why should clients choose to work with you?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.location) {
          stepsList.push({
            target: '.location-input',
            title: 'Location',
            content:
              'Where are you currently located? This helps manage potential time zone differences.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.linkedInLink) {
          stepsList.push({
            target: '.linkedin-link-input',
            title: 'LinkedIn',
            content: 'For internal vetting processes only (not visible to clients).',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.mobilePhone) {
          stepsList.push({
            target: '.mobile-phone-input',
            title: 'Phone Number',
            content: 'Share your phone number with us.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!(consultant?.languages || []).length) {
          stepsList.push({
            target: '.languages-input',
            title: 'Languages',
            content:
              'What languages do you know and what is your proficiency level? This helps us match you with projects that require specific language skills.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.agencyId && !consultant?.cvFile) {
          stepsList.push({
            target: '.resume-input',
            title: 'Resume',
            content: 'For internal vetting processes only (not visible to clients).',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.nickName) {
          stepsList.push({
            target: '.profile-input',
            title: 'Public Profile URL',
            content: 'Setup a unique nickname for your profile.',
            placement: 'left',
            disableBeacon: true,
          });
        }

        if (!consultant?.isPublic) {
          stepsList.push({
            target: '.public-profile-input',
            title: 'Public Profile',
            content:
              'Making your profile public means it is searchable. A public profile can increase your job opportunities!',
            placement: 'left',
            disableBeacon: true,
          });
        }

        setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.submit-button',
            title: 'Save',
            content: 'Click Save button to save your changes.',
            placement: 'right',
            disableBeacon: true,
          });

          setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    consultant?.isPublic,
    consultant?.avatarUrl,
    consultant?.description,
    consultant?.languages,
    consultant?.linkedInLink,
    consultant?.cvFile,
    consultant?.agencyId,
    consultant?.location,
    consultant?.shortDescriptions,
    showIntro,
    user?.displayName,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const toggleDialog = (value: boolean) => {
    setIsDialogOpen(value);
  };

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: isDialogOpen ? 0 : 10000,
            },
          },
          customButtonStyles,
        )}
      />
      {!isModal && (
        <StyledH1Mobile component="div">
          <Typography data-test="consultant-register-header-1" component="h1" variant="h4" mb={2}>
            Tell us about youself
          </Typography>
          <Typography component="p" mb={3} textAlign={'justify'}>
            Build up some base information about yourself and tell us if you wanna share your
            profile with the world.
          </Typography>
        </StyledH1Mobile>
      )}
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={2.5}>
          {businessSetup !== 'not_entered' && (
            <Grid container>
              <StyledFormGroup
                container
                xs={12}
                md={9}
                rowSpacing={{ xs: 1, md: 2 }}
                order={{ xs: -1, md: -2 }}
              >
                <Grid xs={12} md={4}>
                  <StyledInputLabel>
                    First name<sup>*</sup>
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={8} sx={{ mb: { xs: 1 } }}>
                  <Controller
                    control={control}
                    defaultValue={user?.firstName || ''}
                    name="firstName"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        inputRef={ref}
                        error={!!errors.firstName}
                        placeholder={!mobile ? 'Buzz' : 'Enter your first name'}
                        helperText={errors.firstName?.message || 'Incorrect data'}
                        fullWidth={false}
                      />
                    )}
                    rules={{
                      required: 'This field is required',
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Too many spaces',
                      },
                      maxLength: {
                        message: 'The max count of characters is 100',
                        value: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} md={4} mt={mobile ? 1 : undefined}>
                  <StyledInputLabel>
                    Last name<sup>*</sup>
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={8}>
                  <Controller
                    control={control}
                    defaultValue={user?.lastName || ''}
                    name="lastName"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        inputRef={ref}
                        error={!!errors.lastName}
                        placeholder={!mobile ? 'Honeycomb' : 'Enter your last name'}
                        helperText={errors.lastName?.message || 'Incorrect data'}
                      />
                    )}
                    rules={{
                      required: 'This field is required',
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Too many spaces',
                      },
                      maxLength: {
                        message: 'The max count of characters is 100',
                        value: 100,
                      },
                    }}
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <StyledInputLabel>Display Name</StyledInputLabel>
                </Grid>
                <Grid xs={12} md={8}>
                  <Controller
                    control={control}
                    defaultValue={user?.displayName || ''}
                    name="displayName"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        inputRef={ref}
                        className="display-name-input"
                        error={!!errors.displayName}
                        placeholder=""
                        helperText={errors.displayName?.message || 'Incorrect data'}
                      />
                    )}
                    rules={{
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Too many spaces',
                      },
                      maxLength: {
                        message: 'The max count of characters is 100',
                        value: 100,
                      },
                    }}
                  />
                </Grid>
              </StyledFormGroup>
              <Grid
                container
                alignItems="center"
                display="flex"
                xs={12}
                md={3}
                order={{ xs: -2, md: -1 }}
              >
                <Grid xs="auto" display={{ md: 'none' }}>
                  <StyledInputLabel>Profile picture</StyledInputLabel>
                </Grid>
                <Grid xs={6} md={12} className="profile-picture-input">
                  <UploadPhoto
                    size="large"
                    icon={<CameraIcon />}
                    image={consultant?.avatarUrl}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    onDialogToggle={toggleDialog}
                    onUploadPhoto={handleUploadPhoto}
                  />
                </Grid>
              </Grid>
              <StyledFormGroup container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <StyledInputLabel>
                    Headline
                    {!mobile && (
                      <CustomTooltip
                        placement="bottom"
                        title="Use the headline to write a short punch line about yourself. This is the first thing customers see about you."
                      />
                    )}
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={9}>
                  <Controller
                    control={control}
                    defaultValue={consultant?.shortDescriptions || ''}
                    name="shortDescriptions"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        className="short-description-input"
                        inputRef={ref}
                        placeholder="Describe yourself in a few words"
                        error={!!errors.shortDescriptions}
                        helperText={errors.shortDescriptions?.message || 'Incorrect data'}
                      />
                    )}
                    rules={{
                      maxLength: {
                        message: 'The max count of characters is 240',
                        value: 240,
                      },
                    }}
                  />
                </Grid>
              </StyledFormGroup>
              <Grid container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <StyledInputLabel>
                    About me
                    {!mobile && (
                      <CustomTooltip
                        placement="bottom"
                        title="Use this section to write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences."
                      />
                    )}
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={9}>
                  <Controller
                    control={control}
                    defaultValue={consultant?.description || ''}
                    name="description"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        inputRef={ref}
                        className="description-input"
                        error={!!errors.description}
                        helperText={errors.description?.message || 'Incorrect data'}
                        placeholder="Tell us about yourself"
                        multiline
                        rows={2}
                      />
                    )}
                    rules={{
                      maxLength: {
                        message: 'The max count of characters is 1000',
                        value: 1000,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <StyledFormGroup container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <StyledInputLabel>
                    Location<sup>*</sup>
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={9} className="location-input" data-test="location-control">
                  <Controller
                    control={control}
                    defaultValue={{
                      label: consultant?.location,
                      value: { description: consultant?.location },
                    }}
                    name="location"
                    render={({ field: { ref, ...field } }) => (
                      <LocationInput
                        {...field}
                        inputRef={ref}
                        onChange={(val: any) => {
                          geocodeByPlaceId(val.value.place_id)
                            .then(results => {
                              const countryCode = results[0].address_components.find(region =>
                                region.types.find(type => type === 'country'),
                              );

                              setValue('countryIso', countryCode?.short_name || '');
                              setValue('mobilePhone.country', countryCode?.short_name || '');
                            })
                            .catch(error => console.error(error));
                          field.onChange(val);
                        }}
                        error={!!errors.location}
                        errorText={'This field is required'}
                      />
                    )}
                    rules={{ required: 'This field is required' }}
                  />
                </Grid>
              </StyledFormGroup>
              <StyledFormGroup container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <StyledInputLabel>
                    {mobile ? 'LinkedIn profile' : 'LinkedIn'}
                    {!mobile && (
                      <CustomTooltip
                        placement="bottom"
                        title="Add only the last section of your LinkedIn profile URL."
                      />
                    )}
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={9}>
                  <Controller
                    control={control}
                    defaultValue={
                      consultant?.linkedInLink?.replace('https://www.linkedin.com/in/', '') || ''
                    }
                    name="linkedInLink"
                    render={({ field: { ref, ...field } }) => (
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
                          https://www.linkedin.com/in/
                        </Typography>
                        <BootstrapInput
                          {...field}
                          inputRef={ref}
                          className="linkedin-link-input"
                          placeholder={!mobile ? 'buzz-honeycomb' : 'Add link'}
                        />
                      </Box>
                    )}
                  />
                </Grid>
              </StyledFormGroup>
              <StyledFormGroup container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <StyledInputLabel>
                    Phone number<sup>*</sup>
                  </StyledInputLabel>
                </Grid>
                <Grid xs={12} md={9} className="mobile-phone-input">
                  <PhoneNumber
                    name="mobilePhone"
                    placeholder="Type your mobile phone"
                    control={control}
                    defaultValue={mobilePhone}
                    rules={{
                      required: 'This field is required',
                    }}
                    error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                    errorText={
                      (errors.mobilePhone?.nationalNumber as any)?.message ||
                      (errors.mobilePhone?.country as any)?.message
                    }
                    trigger={trigger}
                  />
                </Grid>
              </StyledFormGroup>
              <Grid container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                <Grid xs={12} md={3}>
                  <InputLabel sx={{ mb: 0, mt: 1.5 }}>
                    Language<sup>*</sup>
                  </InputLabel>
                </Grid>
                <Grid container xs={12} md={9} spacing={0} className="languages-input">
                  <Languages
                    items={
                      (consultant?.languages?.length && consultant?.languages) || [
                        { ...DEFAULT_USER_LANGUAGE, langId: 1 },
                      ]
                    }
                  />
                </Grid>
              </Grid>
              {!consultant.agencyId && (
                <StyledFormGroup
                  container
                  xs={12}
                  rowSpacing={{ xs: 1, md: 2 }}
                  flexDirection={'row'}
                >
                  <Grid xs={12} md={3}>
                    <StyledInputLabel>Resume</StyledInputLabel>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9}
                    className="resume-input"
                    display={'flex'}
                    flexDirection={!isiPadOrMobile ? 'row' : 'column'}
                    alignItems={!isiPadOrMobile ? 'center' : undefined}
                    width={!isiPadOrMobile ? '60% !important' : mobile ? '100%' : '55% !important'}
                  >
                    <Controller
                      control={control}
                      name="cv"
                      render={({ field: { ref, ...field } }) => (
                        <CVFileUpload
                          {...field}
                          inputRef={ref}
                          originalFileName={consultant?.cvFile?.originalName}
                          creatorId={user?.id}
                          onChange={e => {
                            trigger();
                            setValue('cv', e);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {consultant.cvFile && (
                    <Grid xs={12} md="auto" mt={mobile ? 1 : undefined}>
                      {isiPadOrMobile ? (
                        <>
                          <Button
                            color="secondary"
                            disableRipple
                            onClick={handleDownloadCV}
                            startIcon={<DownloadIcon className="upload-icon" />}
                            size="small"
                            sx={{ paddingLeft: 0, paddingRight: 0, minWidth: '20px' }}
                          >
                            {mobile ? 'Download Resume' : undefined}
                          </Button>
                          <a ref={downloadLinkRef} style={{ display: 'none' }}>
                            Download Resume
                          </a>
                        </>
                      ) : (
                        <Button
                          color="secondary"
                          disableRipple
                          onClick={handleDownloadCV}
                          startIcon={<DownloadIcon className="upload-icon" />}
                          size="small"
                          sx={{ paddingLeft: 0, paddingRight: 0, minWidth: '20px' }}
                        />
                      )}
                    </Grid>
                  )}
                </StyledFormGroup>
              )}
              {!!consultant.agencyId && (
                <StyledFormGroup container xs={12} rowSpacing={{ xs: 1, md: 2 }}>
                  <Grid xs={12} md={3}>
                    <StyledInputLabel>Profile</StyledInputLabel>
                  </Grid>
                  <Grid xs={12} md="auto">
                    <Controller
                      control={control}
                      defaultValue={consultant?.nickName || ''}
                      name="nickName"
                      render={({ field: { ref, ...field } }) => (
                        <Box display="flex">
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: { xs: 'none', md: 'flex' },
                              height: 48,
                            }}
                          >
                            <Typography>{window.origin}/bee/</Typography>
                          </Box>
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            error={!!errors.nickName}
                            className="profile-input"
                            placeholder={
                              (mobile && `${window.origin}/bee/buzz-honeycomb`) || 'buzz-honeycomb'
                            }
                            helperText={errors.nickName?.message || 'Incorrect data'}
                            onChange={(e: any) => {
                              const value = stringToLowerCaseWithReplaceSpace(
                                e.target.value.replace(/[^\w-]/g, ''),
                              );
                              field.onChange(value);
                              if (value) {
                                debounced(value);
                              }
                            }}
                            onBlur={() => {
                              field.onBlur();
                              debounced.flush();
                            }}
                          />
                        </Box>
                      )}
                      rules={{
                        maxLength: {
                          message: 'The max count of characters is 200',
                          value: 200,
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md="auto" mt={{ xs: 4, md: 0 }} className="public-profile-input">
                    <Controller
                      control={control}
                      defaultValue={consultant?.isPublic}
                      render={({ field }) => (
                        <FormControlLabel
                          data-test="profile-visible-checkbox"
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              size="small"
                              checkedIcon={<span />}
                              icon={<span />}
                            />
                          }
                          label={<Typography>Profile visible to public</Typography>}
                          sx={{ marginLeft: 0 }}
                        />
                      )}
                      name="isPublic"
                    />
                  </Grid>
                </StyledFormGroup>
              )}
            </Grid>
          )}
          {businessSetup === 'not_entered' && <NoBusinessSetup />}
        </Grid>
      </form>
    </>
  );
};

ConsultantFullProfile.displayName = 'AgencyProfile';
ConsultantFullProfile.whyDidYouRender = true;

export default ConsultantFullProfile;
