import React, { FunctionComponent } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from 'styles/icons/EditIcon';
import { MapMarkIcon } from 'styles/icons/MapMarkIcon';

import { EConsultantEditingModals } from 'models/consts';
import {
  IBasicConsultant,
  IMatchedConsultant,
  IProductExperienceFull,
} from 'store/types/consultant';
import { IBasicUser } from 'store/types/user';

import ConsultantExperiencePeriod from '../ConsultantExperiencePeriod';

import { StyledIconBox, StyledName, StyledShortDescriptions } from './styled';
import { StyledOGIcon, StyledVerifiedIcon } from '../ConsultantPreview/styled';

interface INameBlockProps {
  isBlur?: boolean;
  consultant: IBasicConsultant | IMatchedConsultant;
  user: IBasicUser;
  isCurrentUser: boolean;
  isMobile?: boolean;
  expertiseSummary: IProductExperienceFull[];
  onOpen: (open: { type: number; open: boolean }) => void;
  onAnonymousMode?: boolean;
}

const ConsultantNameBlock: FunctionComponent<INameBlockProps> = ({
  consultant,
  expertiseSummary,
  isCurrentUser,
  isMobile,
  onOpen,
  user,
  onAnonymousMode,
}) => (
  <>
    <Box display="flex" justifyContent="space-between">
      <StyledName data-test="consultant-name">
        <b>
          {' '}
          {onAnonymousMode
            ? user?.initials
            : (user?.firstName && [user?.firstName, user?.lastName].join(' ')) || user?.initials}
        </b>
      </StyledName>
      <StyledIconBox>
        {isCurrentUser && (
          <Box sx={{ flex: '1 1 100%', display: { xs: 'block', sm: 'none' } }}>
            <IconButton
              data-test="edit-profile-mobile"
              color="tertiary"
              size="small"
              onClick={() => onOpen({ open: true, type: EConsultantEditingModals.PROFILE })}
            >
              <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
            </IconButton>
          </Box>
        )}
      </StyledIconBox>
    </Box>
    <StyledShortDescriptions variant="subtitle2">
      <strong>{consultant?.shortDescriptions}</strong>
    </StyledShortDescriptions>
    {!isMobile && (
      <Box display="flex" mb={2}>
        {consultant?.isApproved && (
          <Box data-test="approved-profile">
            <StyledVerifiedIcon />
          </Box>
        )}
        <Box>
          <StyledOGIcon />
        </Box>
      </Box>
    )}
    <ConsultantExperiencePeriod expertiseSummary={expertiseSummary} />
    {consultant?.location && (
      <Box display="flex" alignItems="stretch">
        <Box sx={{ ml: 0.375, width: 22, mr: 1 }}>
          <MapMarkIcon />
        </Box>
        <Typography
          component="div"
          sx={{ alignItems: 'center', lineHeight: 'normal', display: 'flex', flex: '1 0 90%' }}
        >
          <strong>{consultant.location}</strong>
        </Typography>
      </Box>
    )}
  </>
);

export default ConsultantNameBlock;
