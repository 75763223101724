import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';

import SortDescIcon from 'styles/icons/SortDescIcon';

import { useAppSelector } from 'store/hooks';
import { useAppDispatch } from 'store/hooks';
import { subTabsForSalesOrTalent } from 'components/Order/OrderList/tabOrder';
import { EOrderFilter, ERole, ESorting } from 'models/consts';
import CuratedPendingOrdersTab from 'components/CuratedOrders/CuratedPendingOrdersTab';
import CuratedOrdersFinalizedOrdersTab from 'components/CuratedOrders/CuratedOrdersFinalizedOrdersTab';
import fetchCuratedOrdersList from 'store/query/curated-order/fetchCuratedOrdersList';
import {
  deleteCuratedOrderSelector,
  fetchCuretedOrderLoadingSelector,
  getCuratedOrderSelector,
} from 'store/selectors/getCuratedOrdersSelector';

const CuratedOrdersLanding: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [filter, setFilter] = React.useState<EOrderFilter>(EOrderFilter.PENDING);

  const curatedOrderList = useAppSelector(getCuratedOrderSelector);

  const pendingOrders = curatedOrderList.filter(order => order.status !== 'active');
  const finalizedOrders = curatedOrderList.filter(order => order.status === 'active');

  const handleChangeFilter = (event: any, newFilter: any) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    dispatch(fetchCuratedOrdersList());
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      mt={{ md: 7, xs: 2 }}
      mb={{ sm: 3 }}
      data-test="sales-order-list-page"
    >
      <Typography mb={{ md: 3 }} sx={{ textTransform: 'capitalize' }} variant="h1">
        Orders
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mt={1}>
        <Tabs
          value={filter}
          variant="scrollable"
          onChange={handleChangeFilter}
          aria-label="Orders"
          textColor="secondary"
          indicatorColor="primary"
        >
          {subTabsForSalesOrTalent.map((tab: any) => (
            <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {filter === EOrderFilter.PENDING && <CuratedPendingOrdersTab orderList={pendingOrders} />}
      {filter === EOrderFilter.FINALIZED && (
        <CuratedOrdersFinalizedOrdersTab orderList={finalizedOrders} />
      )}
    </Box>
  );
};

export default CuratedOrdersLanding;
