import { useEffect } from 'react';
import { useAppSelector } from '../../store/hooks';
import { getMeRoleSelector } from '../../store/selectors/getUserSelector';
import config from '../../app.config';
import { EAuthType, ERole } from '../../models/consts';

const HubSpotChat = () => {
  const ENABLE_CHAT = config.HUBSPOT_CHAT_ENABLED === 'true';
  const HUBSPOT_PORTAL_ID = config.HUBSPOT_TRACKING_ID;
  const role = useAppSelector(getMeRoleSelector);

  useEffect(() => {
    const allowedRoles = [
      ...Object.values(EAuthType),
      ERole.CUSTOMER_ADMIN,
      ERole.CUSTOMER_TEAM_ADMIN,
      ERole.AGENCY_ADMIN,
      ERole.RESOURCE_MANAGER,
    ];

    if (!(ENABLE_CHAT && HUBSPOT_PORTAL_ID && allowedRoles.includes(role?.name))) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://js-na1.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';

    document.body.appendChild(script);

    return () => {
      const scriptElement = document.getElementById('hs-script-loader');
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [role, HUBSPOT_PORTAL_ID, ENABLE_CHAT]);

  useEffect(() => {
    if (!HUBSPOT_PORTAL_ID) {
      console.warn('HubSpot Portal ID is not configured in environment variables');
    }
  }, [HUBSPOT_PORTAL_ID]);

  return null;
};

export default HubSpotChat;
